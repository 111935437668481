import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const YoutubeMusicIcon = createIcon({
  displayName: 'YoutubeMusicIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M21.5583 7.18359C21.3249 6.32472 20.6509 5.6538 19.7882 5.42139C18.2362 5 11.9897 5 11.9897 5C11.9897 5 5.7432 5.01277 4.19119 5.43416C3.32848 5.66657 2.65457 6.33749 2.42113 7.19636C1.95168 9.9418 1.76954 14.1251 2.43395 16.7607C2.6674 17.6196 3.34131 18.2905 4.20401 18.5229C5.75602 18.9443 12.0025 18.9443 12.0025 18.9443C12.0025 18.9443 18.2491 18.9443 19.8011 18.5229C20.6638 18.2905 21.3377 17.6196 21.5711 16.7607C22.0662 14.0114 22.2189 9.83071 21.5583 7.18359Z"
      fill="#FF0000"
    />
  ),
});
