import React from 'react';
import { Text } from '@chakra-ui/react';

const typographyVariants = {
  'section-label': {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '130%',
    textTransform: 'none',
  },
  'heading-large': {
    fontFamily: { base: 'Manrope', md: 'Playfair Display' },
    fontSize: '32px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '120%',
    textTransform: 'none',
  },
  'heading-medium': {
    fontFamily: { base: 'Manrope', md: 'Playfair Display' },
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '130%',
    textTransform: 'none',
  },
  'card-label': {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0.05em',
    lineHeight: '130%',
    textTransform: 'uppercase',
  },
  'heading-small': {
    fontFamily: { base: 'Manrope', md: 'Playfair Display' },
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '130%',
    textTransform: 'none',
  },
  'body-small': {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '130%',
    textTransform: 'none',
  },
  'card-title': {
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '130%',
    textTransform: 'none',
  },
  'body-medium': {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '21px',
    textTransform: 'none',
  },
};

export const Typography = ({ variant = 'body-small', children, ...props }) => {
  const styles = typographyVariants[variant] || typographyVariants['body-small'];

  return (
    <Text {...styles} color="brown.900" {...props}>
      {children}
    </Text>
  );
};
