import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const AmazonMusicIcon = createIcon({
  displayName: 'AmazonMusicIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <rect x="2" y="2" width="20" height="20" rx="10" fill="#24D1DA" />
      <mask
        id="mask0_46_4193"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="8"
        width="14"
        height="8">
        <path d="M19.0001 8.12158L5.02051 8.12158L5.02051 15.8783L19.0001 15.8783L19.0001 8.12158Z" fill="white" />
      </mask>
      <g mask="url(#mask0_46_4193)">
        <path
          d="M14.3122 11.4776C14.218 11.5373 14.0766 11.5821 13.9039 11.5821C13.6369 11.5821 13.3856 11.5522 13.1343 11.4776C13.0715 11.4627 13.0244 11.4478 12.993 11.4478C12.9459 11.4478 12.9302 11.4776 12.9302 11.5373L12.9302 11.6865C12.9302 11.7312 12.9459 11.7611 12.9616 11.7909C12.9773 11.8058 13.0087 11.8356 13.0558 11.8506C13.3071 11.955 13.5898 11.9997 13.9039 11.9997C14.2337 11.9997 14.4849 11.9252 14.6891 11.776C14.8933 11.6268 14.9875 11.4329 14.9875 11.1793C14.9875 11.0003 14.9404 10.8661 14.8461 10.7467C14.7519 10.6423 14.5949 10.5379 14.375 10.4633L13.9353 10.2992C13.7625 10.2396 13.6369 10.1799 13.5898 10.1202C13.527 10.0606 13.4955 10.0009 13.4955 9.89648C13.4955 9.67272 13.6683 9.55339 14.0295 9.55339C14.2337 9.55339 14.4378 9.58323 14.6263 9.64289C14.6891 9.65781 14.7362 9.67272 14.7519 9.67272C14.799 9.67272 14.8304 9.64289 14.8304 9.58323L14.8304 9.43406C14.8304 9.38931 14.8147 9.35947 14.799 9.32964C14.7833 9.29981 14.7519 9.28489 14.7048 9.26998C14.4692 9.19539 14.2337 9.15063 13.9981 9.15063C13.6997 9.15063 13.4484 9.22522 13.26 9.35947C13.0715 9.49372 12.9773 9.68764 12.9773 9.9114C12.9773 10.2545 13.1815 10.5081 13.5898 10.6572L14.0609 10.8213C14.218 10.881 14.3122 10.9257 14.375 10.9854C14.4378 11.0451 14.4535 11.1047 14.4535 11.1942C14.4535 11.3136 14.4064 11.418 14.3122 11.4776Z"
          fill="#28160A"
        />
        <path
          d="M11.8462 9.32977L11.8462 11.3137C11.5792 11.4778 11.3123 11.5673 11.0453 11.5673C10.8725 11.5673 10.7469 11.5225 10.6684 11.433C10.5899 11.3435 10.5584 11.2093 10.5584 11.0154L10.5584 9.32977C10.5584 9.25518 10.527 9.22534 10.4485 9.22534L10.1187 9.22534C10.0402 9.22534 10.0088 9.25518 10.0088 9.32977L10.0088 11.1794C10.0088 11.433 10.0716 11.6419 10.2129 11.7761C10.3543 11.9104 10.5584 11.9849 10.8254 11.9849C11.1866 11.9849 11.5478 11.8656 11.8933 11.6269L11.9248 11.8059C11.9248 11.8507 11.9405 11.8656 11.9719 11.8805C11.9876 11.8954 12.019 11.8954 12.0661 11.8954L12.3017 11.8954C12.3802 11.8954 12.4116 11.8656 12.4116 11.791L12.4116 9.32977C12.4116 9.25518 12.3802 9.22534 12.3017 9.22534L11.9719 9.22534C11.8776 9.22534 11.8462 9.2701 11.8462 9.32977Z"
          fill="#28160A"
        />
        <path
          d="M8.86262 11.9102L9.19242 11.9102C9.27094 11.9102 9.30235 11.8804 9.30235 11.8058L9.30235 9.94122C9.30235 9.68764 9.23954 9.49372 9.09819 9.35947C8.95685 9.22522 8.76839 9.15064 8.50142 9.15064C8.14021 9.15064 7.7633 9.26998 7.40209 9.52356C7.27645 9.26998 7.04089 9.15064 6.69539 9.15064C6.34988 9.15063 6.00438 9.26998 5.65888 9.49372L5.62747 9.32964C5.62747 9.28489 5.61176 9.26998 5.58035 9.25506C5.56465 9.24014 5.53324 9.24014 5.50183 9.24014L5.25056 9.24014C5.17203 9.24014 5.14062 9.26998 5.14062 9.34455L5.14062 11.8207C5.14062 11.8953 5.17203 11.9252 5.25056 11.9252L5.58035 11.9252C5.65888 11.9252 5.69029 11.8953 5.69029 11.8207L5.69029 9.80697C5.95727 9.65781 6.22424 9.56831 6.50693 9.56831C6.66398 9.56831 6.77391 9.61305 6.83673 9.70256C6.89955 9.79206 6.94666 9.9114 6.94666 10.0904L6.94666 11.8058C6.94666 11.8804 6.97807 11.9102 7.05659 11.9102L7.38638 11.9102C7.46491 11.9102 7.49632 11.8804 7.49632 11.8058L7.49632 9.97106L7.49632 9.88156C7.49632 9.85173 7.49632 9.82189 7.49632 9.80697C7.779 9.64289 8.04598 9.56831 8.31296 9.56831C8.47001 9.56831 8.57995 9.61306 8.64275 9.70256C8.70557 9.79206 8.75269 9.9114 8.75269 10.0904L8.75269 11.8058C8.75269 11.8804 8.7841 11.9102 8.86262 11.9102Z"
          fill="#28160A"
        />
        <path
          d="M17.4213 13.894C15.7095 14.5802 13.8406 14.9233 12.1445 14.9233C9.63181 14.9233 7.19758 14.267 5.23451 13.178C5.2031 13.1631 5.17169 13.1482 5.14028 13.1482C5.03035 13.1482 4.96753 13.2675 5.07746 13.3719C6.8992 14.9382 9.31772 15.878 11.9875 15.878C13.9035 15.878 16.1178 15.3111 17.6412 14.2371C17.9082 14.0581 17.6883 13.7896 17.4213 13.894Z"
          fill="#28160A"
        />
        <path
          d="M17.3745 9.82211C17.5159 9.67294 17.7358 9.59835 18.0499 9.59835C18.207 9.59835 18.364 9.61327 18.5053 9.65802C18.5524 9.67294 18.5682 9.67294 18.5995 9.67294C18.6466 9.67294 18.678 9.64311 18.678 9.56853L18.678 9.41936C18.678 9.37461 18.6624 9.32985 18.6467 9.31494C18.6309 9.30002 18.5995 9.27019 18.5682 9.25527C18.364 9.21052 18.1598 9.16577 17.9714 9.16577C17.5316 9.16577 17.2019 9.2851 16.9506 9.53869C16.715 9.77735 16.5894 10.1354 16.5894 10.5829C16.5894 11.0304 16.6992 11.3734 16.9349 11.6121C17.1704 11.8508 17.5002 11.9701 17.94 11.9701C18.1755 11.9701 18.3954 11.9403 18.5682 11.8657C18.6153 11.8508 18.6466 11.8359 18.6624 11.806C18.678 11.7911 18.678 11.7464 18.678 11.7016L18.678 11.5524C18.678 11.4779 18.6466 11.448 18.5995 11.448C18.5838 11.448 18.5524 11.448 18.5211 11.4629C18.3483 11.5077 18.1755 11.5375 18.0185 11.5375C17.7201 11.5375 17.5002 11.4629 17.3589 11.3138C17.2176 11.1646 17.1547 10.9259 17.1547 10.6127L17.1547 10.5381C17.1704 10.2099 17.2332 9.97127 17.3745 9.82211Z"
          fill="#28160A"
        />
        <path
          d="M18.0813 15.2964C18.8979 14.64 19.1178 13.2826 18.945 13.0738C18.8665 12.9843 18.4896 12.8948 18.0184 12.8948C17.5159 12.8948 16.9191 12.9992 16.4637 13.2975C16.3224 13.387 16.3537 13.5064 16.4951 13.4914C16.9819 13.4318 18.0813 13.3124 18.2854 13.5511C18.4738 13.7898 18.0655 14.7743 17.8771 15.2069C17.83 15.3411 17.9398 15.3859 18.0813 15.2964Z"
          fill="#28160A"
        />
        <path
          d="M15.8981 9.22534L15.5684 9.22534C15.4898 9.22534 15.4585 9.25518 15.4585 9.32977L15.4585 11.8059C15.4585 11.8805 15.4898 11.9104 15.5684 11.9104L15.8981 11.9104C15.9767 11.9104 16.0081 11.8805 16.0081 11.8059L16.0081 9.32977C16.0081 9.2701 15.9767 9.22534 15.8981 9.22534Z"
          fill="#28160A"
        />
        <path
          d="M15.9923 8.21108C15.9295 8.15142 15.8353 8.12158 15.7253 8.12158C15.6155 8.12158 15.5369 8.15142 15.4741 8.21108C15.4113 8.27075 15.3799 8.34533 15.3799 8.43483C15.3799 8.52433 15.4113 8.61383 15.4741 8.65858C15.5369 8.71825 15.6155 8.74808 15.7253 8.74808C15.8353 8.74808 15.9139 8.71825 15.9767 8.65858C16.0394 8.59892 16.0709 8.52433 16.0709 8.43483C16.0709 8.34533 16.0552 8.25583 15.9923 8.21108Z"
          fill="#28160A"
        />
      </g>
    </>
  ),
});
