import React, { useState } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { HamburgerIcon } from 'components/icons';
import { Avatar } from '../avatar/avatar';
import { NotificationWidget } from '../notification-widget/notification-widget';
import { Typography } from '../typography/typography';
import { NavSidebarMobile } from 'components/NavSidebar/mobile/nav-sidebar-mobile';
import useUser from 'data-client/use-current-user';
import { NewReleaseButton } from './new-release-button';
import { useUserTierChecks } from 'hooks/authorization-hooks';

export function Header() {
  const [user] = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const { tierDisplayName } = useUserTierChecks();

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  const variant = useBreakpointValue({ base: 'heading-small', md: 'heading-large' });

  return (
    <>
      <Box
        as="header"
        flex={1}
        display="flex"
        justifyContent="space-between"
        pt={{ base: 8, md: '60px' }}
        pb={{ base: 0, md: 8 }}
        alignItems="center">
        <Typography variant={variant} fontWeight={600}>
          Welcome to Venice,{' '}
          <Box as="i" color="brown.700" fontStyle={{ base: 'normal', md: 'italic' }} fontWeight={600}>
            {user?.name}
          </Box>
        </Typography>
        <Box flexDir="row" gap={2} display={{ base: 'none', md: 'flex' }}>
          <NotificationWidget>
            <Avatar>{user?.name}</Avatar>
            <NotificationWidget.Content>
              <NotificationWidget.Title>{user?.name}</NotificationWidget.Title>
              {tierDisplayName ? <NotificationWidget.Subtitle>{tierDisplayName}</NotificationWidget.Subtitle> : null}
            </NotificationWidget.Content>
            <NotificationWidget.Bell />
          </NotificationWidget>
          <NewReleaseButton />
        </Box>
        <Box
          alignItems="center"
          as="button"
          borderColor="brown.700"
          borderRadius="full"
          borderWidth={1}
          display={{ base: 'flex', md: 'none' }}
          height="48px"
          justifyContent="center"
          width="48px"
          onClick={toggleMenu}>
          <HamburgerIcon boxSize={6} color="brown.700" />
        </Box>
      </Box>
      <Box position="relative" display={{ base: 'block', lg: 'none' }}>
        <NavSidebarMobile isOpen={isOpen} closeMenu={closeMenu} />
      </Box>
    </>
  );
}
