import { PAYEE_TYPES, usePaymentAccounts } from 'data-client/user';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import * as API from 'utils/API/API';

const Payment = API.payment();

const getDateRange = interval => {
  const now = DateTime.now();
  const startDate = now.minus({ days: interval });
  const endDate = now.minus({ days: 1 });
  return { startDate: startDate.toISO(), endDate: endDate.toISO() };
};

export function useRoyaltyTransferHome({ currentMetricsInterval }) {
  const [payeeType, setPayeeType] = useState(PAYEE_TYPES.USER);
  const [account, setAccount] = useState();
  const [royaltyTransferSummary, setRoyaltyTransferSummary] = useState({});
  const [royaltyTransfersPerDay, setRoyaltyTransfersPerDay] = useState([]);
  const { paymentAccounts: accounts } = usePaymentAccounts();
  const [dateRange, setDateRange] = useState(getDateRange(currentMetricsInterval.interval));

  const payeeTypeAccountMap = useMemo(
    () =>
      accounts.reduce((acc, account) => {
        acc[account.payeeType] = account;
        return acc;
      }, {}),
    [accounts]
  );

  useEffect(() => {
    setDateRange(getDateRange(currentMetricsInterval.interval));
  }, [currentMetricsInterval]);

  useEffect(() => {
    const accountToSet = payeeTypeAccountMap[payeeType];

    //allows us to set a different payee type in the event that we are missing the default.
    if (!accountToSet) {
      const payeeTypeAccounts = Object.keys(payeeTypeAccountMap);
      const firstPayeeAccountType = payeeTypeAccounts[0];

      //Should prevent infinite render loops in case 0 payee accounts show up.
      if (firstPayeeAccountType && payeeType !== firstPayeeAccountType) {
        setPayeeType(firstPayeeAccountType);
      }
    }

    setAccount(accountToSet);
  }, [accounts, payeeType, payeeTypeAccountMap]);

  const royaltyTransferSummaryQuery = useQuery(
    ['royaltyTransferSummaries', account?.id, dateRange.startDate, dateRange.endDate],
    () => {
      return Payment.getRoyaltyTransferSummariesByAccount(account?.id, dateRange.startDate, dateRange.endDate).then(
        ({ transferred_amount, pending_transfer_amount }) => ({
          transferred_amount,
          pending_transfer_amount,
          accountId: account?.id,
        })
      );
    },
    {
      enabled: !!account?.id,
    }
  );

  useEffect(() => {
    if (royaltyTransferSummaryQuery.isLoading) {
      return;
    }

    if (royaltyTransferSummaryQuery.isSuccess) {
      const { transferred_amount, pending_transfer_amount } = royaltyTransferSummaryQuery.data;
      setRoyaltyTransferSummary({
        accountName: account.name,
        transferred_amount: transferred_amount || 0,
        pending_transfer_amount: pending_transfer_amount || 0,
        pending_transfer_amount_percentage: pending_transfer_amount > 0 ? (pending_transfer_amount / 50) * 100 : 0,
      });
    }
  }, [royaltyTransferSummaryQuery.isLoading, royaltyTransferSummaryQuery.data, payeeType]);

  const royaltyTransferSummaryPerDayQuery = useQuery(
    ['royaltyTransferSummariesPerDay', account?.id, dateRange.startDate, dateRange.endDate],
    () => Payment.getRoyaltyTransferSummariesPerDayByAccount(account?.id, dateRange.startDate, dateRange.endDate),
    {
      enabled: !!account?.id,
    }
  );

  useEffect(() => {
    if (royaltyTransferSummaryPerDayQuery.isLoading) {
      return;
    }

    if (royaltyTransferSummaryPerDayQuery.isSuccess) {
      setRoyaltyTransfersPerDay(royaltyTransferSummaryPerDayQuery.data);
    }
  }, [royaltyTransferSummaryQuery.isLoading, royaltyTransferSummaryQuery.data, payeeType]);

  return { royaltyTransferSummary, royaltyTransfersPerDay };
}
