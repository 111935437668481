import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const MusicNoteIcon = createIcon({
  displayName: 'MusicNoteIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.673 3.667c-.637.113-1.476.39-2.7.799l-4 1.333c-.677.226-1.124.376-1.454.53c-.312.144-.447.261-.533.381s-.155.285-.194.627c-.04.36-.042.833-.042 1.546v2.076l10.5-3.5c0-1.242-.004-2.1-.096-2.725c-.091-.623-.248-.847-.423-.974c-.175-.126-.438-.204-1.058-.093M21.74 6c-.012-.565-.04-1.06-.102-1.483c-.116-.793-.375-1.501-1.03-1.974c-.655-.472-1.409-.493-2.198-.353c-.758.135-1.702.45-2.854.834l-4.098 1.365c-.626.21-1.155.386-1.571.58c-.443.205-.827.46-1.118.864s-.412.849-.467 1.334A9 9 0 0 0 8.255 8H8.25v7.999a3.75 3.75 0 1 0 1.5 3V12.54l10.5-3.5V14a3.75 3.75 0 1 0 1.5 3V6zm-1.49 11a2.25 2.25 0 1 0-4.5 0a2.25 2.25 0 0 0 4.5 0m-12 2a2.25 2.25 0 1 0-4.5 0a2.25 2.25 0 0 0 4.5 0"
      clipRule="evenodd"
    />
  ),
});
