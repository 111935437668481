import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const LightbulbIcon = createIcon({
  displayName: 'LightbulbIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    width: '24px',
    height: '24px',
    fill: 'none',
  },
  path: (
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M12 2.75a6.75 6.75 0 0 0-4.5 11.78l.003.004c.557.499.98.877 1.282 1.155c.285.26.51.474.629.62l.012.015c.222.273.414.511.542.796c.127.284.175.587.231.934l.003.02c.029.178.04.396.045.676h3.506c.005-.28.016-.498.045-.676l.003-.02c.056-.347.104-.65.231-.934c.128-.285.32-.523.542-.796l.012-.015c.119-.146.344-.36.629-.62c.302-.278.725-.656 1.282-1.155l.004-.004A6.75 6.75 0 0 0 12 2.75m1.744 17.5h-3.488q.006.155.016.277c.02.225.055.307.079.348a.75.75 0 0 0 .274.274c.04.024.123.058.348.079c.235.021.546.022 1.027.022s.792 0 1.027-.022c.225-.02.307-.055.348-.079a.75.75 0 0 0 .274-.274c.024-.04.059-.123.079-.348q.01-.122.016-.277M3.75 9.5a8.25 8.25 0 1 1 13.752 6.148a169 169 0 0 0-1.274 1.146a16 16 0 0 0-.44.42l-.036.039c-.26.321-.317.404-.35.479c-.034.075-.058.172-.123.58c-.026.161-.029.437-.029 1.188v.032c0 .44 0 .82-.028 1.13c-.03.33-.096.656-.273.963a2.25 2.25 0 0 1-.824.824c-.307.177-.633.243-.962.273c-.312.028-.691.028-1.13.028h-.065c-.44 0-.82 0-1.13-.028c-.33-.03-.656-.096-.963-.273a2.25 2.25 0 0 1-.824-.824c-.177-.307-.243-.633-.273-.962c-.028-.312-.028-.691-.028-1.13V19.5c0-.75-.003-1.027-.029-1.188c-.065-.408-.09-.505-.123-.58s-.09-.158-.35-.48l-.01-.01l-.026-.029l-.097-.097a17 17 0 0 0-.343-.322c-.295-.27-.712-.643-1.274-1.146A8.23 8.23 0 0 1 3.75 9.5" />
      <path d="M13.222 7.89a.75.75 0 0 1 .174 1.046L12.1 10.75h1.543a.75.75 0 0 1 .61 1.186l-2.143 3a.75.75 0 1 1-1.22-.872l1.295-1.814h-1.542a.75.75 0 0 1-.61-1.186l2.142-3a.75.75 0 0 1 1.047-.174" />
    </g>
  ),
});
