import React from 'react';
import { Widget } from './widget';
import { Typography } from 'components/v2/typography/typography';
import { Box } from '@chakra-ui/react';
import numeral from 'numeral';

const StatementSection = ({ title, value, children }) => (
  <Box
    display="flex"
    flexDir="column"
    gap={4}
    borderBottomWidth={1}
    borderBottomStyle="solid"
    pb={5}
    borderBottomColor="brown.300">
    <Typography variant="card-label" color="brown.700">
      {title}
    </Typography>
    <Typography variant="heading-medium">{value}</Typography>
    {children}
  </Box>
);

const ProgressBar = ({ percentage }) => (
  <Box width="100%" height="8px" bg="#E7DFCD" borderRadius="full" overflow="hidden">
    <Box height="100%" width={`${percentage}%`} bg="#28160A" transition="width 0.3s ease-in-out" />
  </Box>
);

export function Statements({ summary, summaryPerDay = [] }) {
  const { transferred_amount, pending_transfer_amount, pending_transfer_amount_percentage, accountName } =
    summary || {};
  return (
    <Widget title="Statements">
      <StatementSection
        title={`Total Paid${accountName ? ` to ${accountName}` : ''}`}
        value={numeral(transferred_amount).format('$0,0.00')}
      />
      {pending_transfer_amount < 50 ? (
        <StatementSection
          title="Earnings - awaiting $50 threshold"
          value={numeral(pending_transfer_amount).format('$0,0.00')}>
          <ProgressBar percentage={pending_transfer_amount_percentage || 0} />
        </StatementSection>
      ) : (
        <StatementSection
          title="Pending transfers for next payout"
          value={numeral(pending_transfer_amount).format('$0,0.00')}
        />
      )}

      <Box gap={2} display="flex" flexDir="column">
        <Typography variant="card-label" color="brown.700">
          Earnings in the period
        </Typography>
        <Widget.Chart height={68} bg="brown.100">
          <Widget.EarningsChart data={summaryPerDay} />
        </Widget.Chart>
      </Box>
      <Widget.ViewReportButton url="/statements" title="View full report" />
    </Widget>
  );
}
