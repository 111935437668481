import React from 'react';
import { Box, Link as ChakraLink } from '@chakra-ui/react';
import * as Icons from 'components/icons';
import { ChevronRightIcon } from 'components/icons/chevron-right';
import { QuestionCircleIcon } from 'components/icons/question-circle';
import { SettingsIcon } from 'components/icons/settings';
import useCurrentUser from 'data-client/use-current-user';
import { Link, useLocation } from 'react-router-dom';
import { isFlagEnabled } from 'utils/flag-checks';
import HEAP from '../../constants/HEAP.gen.json';
import { hasPermission } from '../../permissions/has-permission';
import { NavSidebarLogo } from './nav-sidebar-logo';
const flags = require('../../constants/FLAGS.gen.json');

export function useNavbarItems() {
  const [user] = useCurrentUser();
  const permissions = user?.permissions;
  const groupId = user?.currentGroup?.id;

  const NAV_ITEMS = [
    {
      id: 'dashboard',
      to: `/`,
      label: 'Dashboard',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_DASHBOARD,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Data Dashboard - Label View', 'Data Dashboard - Track View'],
          permissions,
        }),
      icon: Icons.UploadIcon,
    },
    {
      id: 'catalog',
      to: `/search/releases`,
      label: 'Your Catalog',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_CATALOG,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Releases'],
          permissions,
        }),
      icon: Icons.MusicNoteIcon,
    },
    {
      id: 'co-manager',
      to: `/co-manager`,
      label: 'Co-Manager',
      shouldRender: () =>
        hasPermission({
          featureNames: ['Venice Co-Manager'],
          permissions,
          groupId,
        }),
      icon: Icons.SparkleIcon,
    },
    {
      id: 'statements',
      to: `/statements`,
      label: 'Statements',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_STATEMENTS,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Label Statements', 'Payee Statements'],
          permissions,
        }),
      icon: Icons.CheckSquareIcon,
    },
    {
      id: 'analytics',
      to: `/analytics`,
      label: 'Analytics',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_ANALYTICS,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Data Dashboard - Label View', 'Data Dashboard - Track View'],
          permissions,
          groupId,
        }),
      icon: Icons.ChartIcon,
    },
    {
      id: 'marketing',
      to: isFlagEnabled(flags.ENABLE_MARKETING_DISCOVERY_MODE) ? '/marketing' : '/marketing-links',
      label: 'Marketing Tools',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_MARKETING_LINKS,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Releases'],
          permissions,
          groupId,
        }),
      icon: Icons.FolderWithFilesIcon,
    },
    {
      id: 'workshops',
      to: 'https://community.venicemusic.co/spaces/14005711/content',
      newTab: true,
      label: 'Workshops',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_WORKSHOPS,
      shouldRender: () => true,
      icon: Icons.PlayIcon,
    },
    {
      id: 'community',
      to: 'https://community.venicemusic.co',
      newTab: true,
      label: 'Community',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_COMMUNITY,
      shouldRender: () => true,
      icon: Icons.UserHeartIcon,
    },
    {
      id: 'resources',
      to: 'https://www.venicemusic.co/blog',
      newTab: true,
      label: 'Resources',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_RESOURCES,
      shouldRender: () => true,
      icon: Icons.DocumentsIcon,
    },
  ];

  const NAV_BOTTOM_ITEMS = [
    { label: 'Settings', icon: SettingsIcon, to: '/settings', shouldRender: () => true },
    {
      label: 'Need Help?',
      icon: QuestionCircleIcon,
      to: 'https://support.venice.tech',
      newTab: true,
      shouldRender: () => true,
    },
  ];

  return { NAV_ITEMS, NAV_BOTTOM_ITEMS };
}

export const NavSidebar = props => {
  const { NAV_ITEMS, NAV_BOTTOM_ITEMS } = useNavbarItems();
  return (
    <Box
      id="navSidebar"
      position="sticky"
      top={0}
      borderBottomRightRadius={32}
      borderTopRightRadius={32}
      borderWidth={1}
      borderColor="brown.400"
      bg="brown.100"
      width={252}
      height="100vh"
      overflowY="auto"
      {...props}>
      <Box height="100%" display="flex" flexDir="column">
        <Box borderBottomWidth={1} borderColor="brown.400" px={6} py={7}>
          <NavSidebarLogo />
        </Box>
        <Box display="flex" flex={1} flexDir="column" justifyContent="space-between">
          <NavSidebarList>
            {NAV_ITEMS.map(({ icon: Icon, label, ...rest }) => (
              <NavSidebarListItem key={label} {...rest}>
                <Icon boxSize={4} color="brown.700" /> {label}
              </NavSidebarListItem>
            ))}
          </NavSidebarList>
          <NavSidebarList>
            {NAV_BOTTOM_ITEMS.map(({ label, icon: Icon, ...rest }) => (
              <NavSidebarListItem key={label} arrowIcon={false} {...rest}>
                <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                  {label} <Icon color="brown.700" />
                </Box>
              </NavSidebarListItem>
            ))}
          </NavSidebarList>
        </Box>
      </Box>
    </Box>
  );
};

export const NavSidebarList = ({ children, ...rest }) => (
  <Box as="ul" listStyleType="none" m={0} p={0} px={6} py={8} gap={1} display="flex" flexDirection="column" {...rest}>
    {children}
  </Box>
);

export const NavSidebarListItem = ({ children, arrowIcon = true, to, newTab = false, shouldRender }) => {
  const location = useLocation();
  const isActive = to ? location?.pathname === to : false;

  const linkStyles = {
    borderColor: isActive ? 'brown.400' : 'brown.100',
    bg: isActive ? 'brown.300' : 'transparent',
    borderRadius: 'xl',
    borderWidth: 1,
    color: 'brown.900',
    cursor: 'pointer',
    fontSize: 'reg',
    px: 2,
    py: 2,
    display: 'block',
    transition: 'background 0.2s ease-in-out, border-color 0.2s ease-in-out',
    _hover: {
      bg: 'brown.300',
      borderColor: 'brown.400',
      '.arrow-icon': { opacity: 1, transform: 'translateX(4px)' },
    },
  };

  return shouldRender() ? (
    <Box as="li">
      <ChakraLink
        as={!newTab ? Link : 'a'}
        href={newTab ? to : undefined}
        to={!newTab ? to : undefined}
        target={newTab ? '_blank' : '_self'}
        rel={newTab ? 'noopener noreferrer' : undefined}
        {...linkStyles}>
        <Box display="flex" alignItems="center" justifyContent={arrowIcon ? 'space-between' : 'flex-start'}>
          <Box gap={2} display="flex" alignItems="center" flex={1}>
            {children}
          </Box>
          {arrowIcon && (
            <ChevronRightIcon
              className="arrow-icon"
              transform={isActive ? 'translateX(4px)' : undefined}
              opacity={isActive ? 1 : 0}
              transition="opacity 0.2s ease-in-out, transform 0.2s ease-in-out"
            />
          )}
        </Box>
      </ChakraLink>
    </Box>
  ) : null;
};
