import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const HamburgerIcon = createIcon({
  displayName: 'HamburgerIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.75 7a.75.75 0 0 1-.75.75H4a.75.75 0 0 1 0-1.5h16a.75.75 0 0 1 .75.75m0 5a.75.75 0 0 1-.75.75H4a.75.75 0 0 1 0-1.5h16a.75.75 0 0 1 .75.75m0 5a.75.75 0 0 1-.75.75H4a.75.75 0 0 1 0-1.5h16a.75.75 0 0 1 .75.75"
      clipRule="evenodd"
    />
  ),
});
