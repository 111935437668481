import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { TotalStreams } from './widgets/total-streams';
import { TopDSPs } from './widgets/top-dsps';
import { Statements } from './widgets/statements';

export function NumbersWidget({ data = {} }) {
  const {
    totalStreams,
    topTracks,
    totalStreamsChartSeries,
    dateRangeString,
    topDsps,
    topDspsChartSeries,
    royaltyTransferSummary,
    royaltyTransfersPerDay,
  } = data;

  return (
    <Grid templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }} gap={5}>
      <GridItem>
        <TotalStreams
          data={topTracks}
          chartSeries={totalStreamsChartSeries}
          dateRangeString={dateRangeString}
          totalStreams={totalStreams}
        />
      </GridItem>
      <GridItem>
        <TopDSPs data={topDsps} chartSeries={topDspsChartSeries} />
      </GridItem>
      <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
        <Statements summary={royaltyTransferSummary} summaryPerDay={royaltyTransfersPerDay} />
      </GridItem>
    </Grid>
  );
}
