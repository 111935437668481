import React from 'react';
import { Box } from '@chakra-ui/react';
import { Typography } from '../typography/typography';
import { Button } from '../button/button';
import { ArrowRightIcon } from 'components/icons';

export function Hero() {
  return (
    <Box bg="brown.900" py="80px" borderRadius="xl" display="flex" justifyContent="center" alignItems="center">
      <Box textAlign="center" gap={3} display="flex" flexDir="column">
        <Typography variant="heading-large" color="brown.300">
          Get a Personalized Rollout Strategy for Your Release
        </Typography>
        <Typography variant="body-small" color="brown.300">
          Answer a few questions and we’ll craft a 4-Phase Strategy to help you promote it
        </Typography>
        <a href="https://zthwflczjj2.typeform.com/to/WbDtSF0P" style={{ textDecoration: 'none' }}>
          <Button variant="link" endIcon={<ArrowRightIcon />}>
            Get Your Strategy
          </Button>
        </a>
      </Box>
    </Box>
  );
}
