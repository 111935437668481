import React from 'react';
import { Box } from '@chakra-ui/react';
import { Typography } from '../typography/typography';

export function Avatar({ src, children }) {
  const getInitials = name => {
    if (!name) return '';
    const words = name.split(' ');
    return words
      .filter(word => word.length > 0)
      .map(word => word[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
  };

  return (
    <Box
      w="32px"
      h="32px"
      borderWidth={1}
      borderColor={src ? 'accent.500' : 'brown.700'}
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="brown.500"
      overflow="hidden">
      {src ? (
        <Box as="img" alt="" src={src} w="30px" h="30px" borderRadius="full" />
      ) : (
        <Typography variant="body-small">{getInitials(children)}</Typography>
      )}
    </Box>
  );
}
