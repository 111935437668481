import React from 'react';
import { Link } from '@chakra-ui/react';
import { Card } from 'components/card/card';
import { Typography } from 'components/v2/typography/typography';
import { Box } from '@chakra-ui/react';
import { Button } from 'components/v2/button/button';
import { ArrowRightIcon } from 'components/icons';
import { DateTime } from 'luxon';

const MAX_EVENTS_NUMBER = 3;
const UPGRADE_URL =
  '/settings?utm_content=events&utm_source=membership_portal&utm_campaign=in_product_upgrade&utm_term=interested+in+events&utm_medium=web_app#membership';

export function UpcomingEvents({ events, canAccessEvents }) {
  return (
    <Card p={4} borderColor="brown.500" bg="brown.300" borderRadius="xl">
      <Typography variant="card-label" color="brown.700" mb={4}>
        Upcoming events
      </Typography>
      <Box flexDir="column" display="flex" gap={2}>
        {events?.slice(0, MAX_EVENTS_NUMBER).map(item => {
          const startDateTime = DateTime.fromISO(item.starts_at);
          const eventDate = startDateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
          const eventTime = startDateTime.toLocaleString(DateTime.TIME_SIMPLE);
          const switchToJoinCutoff = DateTime.now().plus({ minutes: 15 });

          return (
            <Card key={item.id} borderRadius="xl" p={4}>
              <Box display="flex" flexDir="column" gap={2} alignItems="flex-start">
                <Typography variant="body-small" color="brown.700">
                  {eventDate} at {eventTime}
                </Typography>
                <Typography variant="body-small" fontWeight="600">
                  {item.title}
                </Typography>
                {canAccessEvents && (
                  <Button
                    endIcon={<ArrowRightIcon />}
                    variant="link"
                    fontSize="12px"
                    as={Link}
                    href={item.link}
                    target="_blank">
                    {startDateTime > switchToJoinCutoff ? 'RSVP' : 'Join Event'}
                  </Button>
                )}
                {!canAccessEvents && (
                  <Button endIcon={<ArrowRightIcon />} variant="link" fontSize="12px" as="a" href={UPGRADE_URL}>
                    Upgrade to RSVP
                  </Button>
                )}
              </Box>
            </Card>
          );
        })}
      </Box>
    </Card>
  );
}
