import React from 'react';
import { Box } from '@chakra-ui/react';
import { BellIcon } from 'components/icons';
import { Typography } from '../typography/typography';
import { Link } from 'react-router-dom';

export function NotificationWidget({ children }) {
  return (
    <Link to="/settings">
      <Box
        _hover={{ bg: 'brown.500' }}
        alignItems="center"
        borderColor="brown.400"
        borderRadius="full"
        borderWidth={1}
        display="flex"
        flexDirection="row"
        p={2}
        pr={3}>
        {children}
      </Box>
    </Link>
  );
}

NotificationWidget.Content = function Content({ children }) {
  return <Box ml={3}>{children}</Box>;
};

NotificationWidget.Title = function Title({ children }) {
  return <Typography variant="card-title">{children}</Typography>;
};

NotificationWidget.Subtitle = function Subtitle({ children }) {
  return <Typography variant="body-small">{children}</Typography>;
};

NotificationWidget.Bell = function Bell() {
  return (
    <Box w={8} h={8} ml={1} display="flex" alignItems="center" justifyContent="center">
      <BellIcon color="brown.900" />
    </Box>
  );
};
