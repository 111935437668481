import React from 'react';
import { Header } from 'components/v2/header/header';
import { Hero } from 'components/v2/hero/hero';
import { ViewWrapper } from 'components/v2/view-wrapper/view-wrapper';
import { EventsAndWorkshops } from './components/events-and-workshops';
import { Box, Grid, GridItem, Select } from '@chakra-ui/react';
import { NumbersWidget } from './components/numbers-widget';
import { GuidanceAtEveryStep } from './components/guidance-at-every-step';
import { CoManager, CoManagerInput } from './components/co-manager';
import { useHomeNumbersWidget } from 'hooks/use-home-numbers-widget';
import { useGetMembershipEvents } from 'data-client/use-membership-events';
import { useGetHomeWorkshops } from 'data-client/use-home-workshops';
import { SearchIcon } from 'components/icons/search-icon';
import { useUserTierChecks } from '../../hooks/authorization-hooks';

const METRICS_PERIOD_INTERVALS = require('../../constants/METRICS_PERIOD_INTERVALS.gen.json');

const Home = () => {
  const widgetData = useHomeNumbersWidget();
  const { currentMetricsInterval, handleTimeRangeChange } = widgetData;

  const { isLoading: isLoadingEvents, data: membershipEvents } = useGetMembershipEvents();
  const events = membershipEvents?.sort((a, b) => a.starts_at - b.starts_at);

  const { data: workshops, isLoading: isLoadingWorkshops } = useGetHomeWorkshops();

  const { canAccessEvents } = useUserTierChecks();

  return (
    <ViewWrapper>
      <Box gap={6} display="flex" flexDir="column">
        <Header />
        <Box as="div" display={{ base: 'block' }}>
          <Hero />
        </Box>
        <EventsAndWorkshops
          events={events}
          isLoadingEvents={isLoadingEvents}
          workshops={workshops}
          isLoadingWorkshops={isLoadingWorkshops}
          canAccessEvents={canAccessEvents}
        />
        <Grid templateColumns={{ base: '1fr', md: '2.05fr 1fr' }} gap={5} width="100%">
          <GridItem>
            <CoManagerInput placeholder="Ask Co-Manager anything…" InputIcon={SearchIcon} />
          </GridItem>
          <GridItem display="flex" alignItems="center">
            <Select
              borderColor="brown.400"
              color="brown.700"
              _focus={{ outline: 'none' }}
              bg="brown.100"
              borderRadius="xl"
              value={currentMetricsInterval?.interval}
              onChange={handleTimeRangeChange}
              height="52px"
              width="100%">
              {Object.values(METRICS_PERIOD_INTERVALS).map(interval => (
                <option key={`option-${interval.interval}`} value={interval.interval}>
                  {interval.friendly}
                </option>
              ))}
            </Select>
          </GridItem>
        </Grid>
        <NumbersWidget data={widgetData} />
        <GuidanceAtEveryStep />
        <CoManager />
      </Box>
    </ViewWrapper>
  );
};

export default Home;
