import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { NavSidebar } from 'components/NavSidebar/nav-sidebar';

export function ViewWrapper({ children }) {
  useEffect(() => {
    // Change the background color when `.wrapper-v2` is present
    const wrapper = document.querySelector('.wrapper-v2');
    if (wrapper) {
      document.body.style.backgroundColor = '#E7DFCD';
    } else {
      document.body.style.backgroundColor = '';
    }

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <Box display="flex" flexDirection="row" bg="brown.300" className="wrapper-v2">
      <Box position="relative" display={{ base: 'none', lg: 'block' }}>
        <NavSidebar />
      </Box>
      <Box display="flex" flex={1} justifyContent="center" pb="60px">
        <Box maxW={{ lg: '1100px' }} px={{ base: 2 }} w="100%">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
