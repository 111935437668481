import React from 'react';
import { Widget } from './widget';

export function TopDSPs({ data, chartSeries }) {
  return (
    <Widget title="Top DSPs">
      <Widget.Chart bg="brown.100">
        <Widget.TopDspsChart data={chartSeries} />
      </Widget.Chart>
      <Widget.ItemList data={data} />
      <Widget.ViewReportButton />
    </Widget>
  );
}
