import React from 'react';
import { Box } from '@chakra-ui/react';

export const PromptLibraryLabel = ({ label, onClick, isSelected }) => {
  return (
    <Box
      border="1px solid"
      borderRadius={30}
      p="2px 15px"
      textAlign="center"
      borderColor="cream200"
      bg={isSelected ? 'cream90' : 'white'}
      cursor="pointer"
      color="black70"
      onClick={onClick}>
      {label}
    </Box>
  );
};
