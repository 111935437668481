import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Button } from '../button/button';
import TerminatedAgreementModal from 'components/SearchResults/terminated-agreement-modal';
import useUser from 'data-client/use-current-user';
import { useUserTierChecks } from 'hooks/authorization-hooks';
import { useHistory } from 'react-router-dom';

export function NewReleaseButton() {
  const { isExecutiveTier } = useUserTierChecks();
  const [user] = useUser();
  const terminatedAgreementModal = useDisclosure();
  const history = useHistory();

  const handleCreateReleaseButton = () => {
    if (isExecutiveTier && !user?.serviceDeal) {
      terminatedAgreementModal.onOpen();
      return;
    }
    history.push('/manage');
  };

  return (
    <>
      <Button disabled={!user?.subscription?.isActive} onClick={handleCreateReleaseButton}>
        Release a New Song
      </Button>
      <TerminatedAgreementModal
        isOpen={terminatedAgreementModal.isOpen}
        onClose={terminatedAgreementModal.onClose}
        variant="short"
        cancelText="close"
      />
    </>
  );
}
