import React, { useState } from 'react';
import { Box, Grid, GridItem, Link as ChakraLink } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from 'components/card/card';
import { CardTitle } from 'components/card/card-title';
import { Typography } from 'components/v2/typography/typography';
import { VeniceIcon } from 'components/icons';
import { MapArrowRightIcon } from 'components/icons/map-arrow-right';

const questions = [
  { id: 1, title: 'Create a bio for my streaming profiles.' },
  { id: 2, title: 'What should my timeline look like for my release?' },
  { id: 3, title: 'How can I start building a fan base before my release?' },
  { id: 4, title: 'Help me pick a date for my next release' },
  { id: 5, title: 'What should I post to introduce myself to new listeners?' },
  { id: 6, title: 'How often should I release?' },
];

const coManagerMessages = [
  "Hey there! I'm Co-Manager 👋",
  'I’ve been trained by top managers and marketers on thousands of strategies that have helped artists succeed, so I’m here to support you no matter what stage you’re at.',
  'You can ask me anything about your music or career, but I get even better when you upload with Venice. That way, I can personalize my guidance, tailoring advice directly to your music and goals.',
  "Let's get started!",
];

const CoManagerMessage = () => (
  <Card bg="#EFE9DD" display="flex" flexDir="column" gap={4} borderRadius="xl" borderBottomRightRadius={0}>
    {coManagerMessages.map((msg, index) => (
      <Typography key={index} variant="card-title" fontWeight={400}>
        {msg}
      </Typography>
    ))}
  </Card>
);

export const CoManagerInput = ({ placeholder = 'Ask Co-Manager anything', InputIcon = MapArrowRightIcon }) => {
  const [inputValue, setInputValue] = useState('');
  const history = useHistory();

  const handleRedirect = () => {
    if (inputValue.trim()) {
      history.push(`/co-manager?prompt=${encodeURIComponent(inputValue)}`);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleRedirect();
    }
  };

  return (
    <Box
      height="52px"
      bg="brown.100"
      borderRadius="xl"
      pl={3}
      pr={1}
      borderWidth={1}
      borderColor="brown.400"
      display="flex"
      alignItems="center"
      gap={3}
      pos="relative">
      <VeniceIcon color="accent.500" />
      <Box
        as="input"
        placeholder={placeholder}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        w="100%"
        _placeholder={{ color: 'brown.700' }}
        height="100%"
        bg="transparent"
        border="none"
        color="brown.900"
        outline="none"
      />
      <Box as="button" w="52px" h="52px" outline="none" onClick={handleRedirect}>
        <InputIcon color="#BFB8AD" />
      </Box>
    </Box>
  );
};

const CoManagerChat = () => (
  <Card
    bg="brown.300"
    borderRadius="xl"
    p={8}
    height="100%"
    display="flex"
    flexDir="column"
    justifyContent="space-between">
    <Typography variant="card-label" color="brown.700">
      Co-Manager chat
    </Typography>
    <Box display="flex" flexDir="column" gap={4}>
      <CoManagerMessage />
      <CoManagerInput />
    </Box>
  </Card>
);

const SuggestedQuestion = ({ title }) => (
  <ChakraLink as={Link} to={`/co-manager?prompt=${title}`} _hover={{ textDecoration: 'none' }}>
    <Card bg="brown.100" borderRadius="xl" borderWidth={0} color="brown.900" display="flex" flexDir="row" gap={2} p={5}>
      <VeniceIcon color="accent.500" />
      <Typography variant="body-medium">{title}</Typography>
    </Card>
  </ChakraLink>
);

const SuggestedQuestions = () => (
  <Box display="flex" flexDir="column" gap={2}>
    {questions.map(item => (
      <SuggestedQuestion key={item.id} title={item.title} />
    ))}
  </Box>
);

export function CoManager() {
  return (
    <Card variant="large">
      <CardTitle title="Co-Manager" description="Get Answers to all of Your Career Questions" />
      <Grid templateColumns="repeat(12, 1fr)" gap={5}>
        <GridItem colSpan={{ base: 12, sm: 6, md: 6 }}>
          <CoManagerChat />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 6, md: 6 }}>
          <SuggestedQuestions />
        </GridItem>
      </Grid>
    </Card>
  );
}
