/* eslint-disable no-sparse-arrays */
//The switch component is doing something dumb

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogLogs } from '@datadog/browser-logs';
import PrivateRoute from '../components/PrivateRoute/private-route';
import { useGlobalData } from 'utils/global-data';

import GenericWithCtaHome from '../views/ErrorPages/generic-with-cta-home';
import ManageRelease from '../views/ManageRelease/manage-release';
import Manage from '../manage/manage-router'; //eventually New
import Home from '../views/Home/home';
import HomeV2 from '../views/Home/home-v2';
import Search from '../views/Search/search';
import ReleaseStatus from '../views/ReleaseStatus/release-status';
import AnalyticsRouter from '../analytics/analytics-router';
import MarketingLinksRouter from 'marketing-links/marketing-links-router';
import SettingsRouter from '../settings/settings-router';
import StatementsRouter from 'views/Statements/statements-router';
import GetDspUriRouter from 'features/DspUri/get-dsp-uri-router';
import MarketingRouter from 'marketing/marketing-router';
import MarketingDiscoveryModeRouter from 'marketing-discovery-mode/marketing-discovery-mode-router';

import { USER_ROLES, API_STATUS_KEYS, SEARCH_RESULT_TYPES } from '../constants/constants';
import CoManagerFullscreen from 'views/co-manager/co-manager-fullscreen';

export const APP_VIEWS = {
  ARTIST_DETAIL: 'ARTIST_DETAIL',
  ARTIST_EDIT: 'ARTIST_EDIT',
  ARTIST_GALLERY: 'ARTIST_GALLERY',
  HOME: 'HOME',
  LABEL_DASHBOARD: 'LABEL_DASHBOARD',
  TRACK_DASHBOARD: 'TRACK_DASHBOARD',
  SEARCH: 'SEARCH',
  OMNI_MESSAGE: 'OMNI_MESSAGE',
  RELEASE_DETAIL: 'RELEASE_DETAIL',
  RELEASE_CREATE: 'RELEASE_CREATE',
  RELEASE_EDIT: 'RELEASE_EDIT',
  SETTINGS: 'SETTINGS',
  SPLITS_AND_PAYMENTS: 'SPLITS_AND_PAYMENTS',
  STATEMENTS: 'STATEMENTS',
  TRACK_DETAIL: 'TRACK_DETAIL',
  SANDBOX: 'SANDBOX',
  PRIVACY: 'PRIVACY',
  TERMS: 'TERMS',
};

export const ENTITY_FILTER_URL_MAP = {
  [SEARCH_RESULT_TYPES.RELEASES]: 'releases',
};

function Routes({ isPrivate, logout }) {
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  const { dbUser, dbUserStatus, allUserAttributesLoaded, userPermissions, selectedUserGroup } = useGlobalData();

  const handleLogout = () => {
    window.heap && window.heap.resetIdentity();
    logout();
  };

  const noBackend = !dbUser && dbUserStatus[API_STATUS_KEYS.ERROR];

  // wait for role, group, and user account to load
  if ((!noBackend && !userPermissions.role && !selectedUserGroup) || !dbUser) return null;

  // wait for all user attributes (e.g. isPayee) to load
  if (!allUserAttributesLoaded) return null;

  // TODO: add handling for null userPermissions.role, i.e. loading?

  // This hash is set in Auth0, under Email Templates > Password Reset
  //  If a label user creates a new payee with an owned email address,
  //  then clicks the confirm email and sets a password, their label user
  //  should log out. Otherwise, they auto-auth with their label user after
  //  confirming their payee account password which is confusing
  const isPayeePasswordChange = window.location.hash === '#payee-password-change';

  if (isPayeePasswordChange && [USER_ROLES.SYSTEM_ADMIN, USER_ROLES.LABEL_MEMBER].includes(userPermissions.role)) {
    handleLogout();
    return <></>;
  }

  const isUserPayee = !dbUser?.currentGroup;
  const userCanSeeCatalogue =
    dbUser?.currentGroup?.role === 'Label Admin' || userPermissions.role === USER_ROLES.SYSTEM_ADMIN;
  const userMustContactSupport = dbUser?.must_contact_support;

  const onErrorHandler = (error, info) => {
    datadogLogs.logger.info('Error boundary hit within routes', {
      pathname: window?.location?.pathname,
      error,
      info,
      userId: dbUser?.id,
    });
  };

  return (
    <ErrorBoundary key={window?.location?.pathname} FallbackComponent={GenericWithCtaHome} onError={onErrorHandler}>
      <Switch>
        {/* Make sure we redirect users to contact support if they are locked out */}
        <RouteComponent
          path="/contact-support"
          render={props => (
            <GenericWithCtaHome
              headingMessage="Your account is under review"
              bodyMessage={
                'Your account is locked out while we review an issue related to copyright claims, ToS violation or account security. Please reach out at the email below from ' +
                dbUser?.email +
                ' to help provide any necessary information we might need to re-instate your account.'
              }
              {...props}
            />
          )}
        />
        {userMustContactSupport && <Redirect to="/contact-support" />}

        <RouteComponent
          path="/logout"
          render={() => {
            handleLogout();
            return <></>;
          }}
        />
        {/* Hide for basic users */}
        {!isUserPayee && [
          <RouteComponent key={0} path="/" exact render={props => <HomeV2 name={APP_VIEWS.HOME} {...props} />} />,
          <RouteComponent key={0} path="/v1" exact render={props => <Home name={APP_VIEWS.HOME} {...props} />} />,
          <RouteComponent
            /* Tech Debt: search is a misnomer. It should be catalog */

            key={1}
            path="/search/:entity_type?/:search_term?"
            exact
            render={props => <Search name={APP_VIEWS.SEARCH} {...props} />}
          />,
          <RouteComponent key={3} path="/releases" exact render={() => <Redirect to="/search/releases" />} />,
          <RouteComponent key={7} path="/manage/" render={props => <Manage />} />,
          <RouteComponent
            key={4}
            path="/releases/new/:stack_index?/:card_index?"
            render={props => <ManageRelease name={APP_VIEWS.RELEASE_CREATE} {...props} />}
          />,
          <RouteComponent
            key={5}
            path="/releases/:release_id"
            exact
            render={props => <ReleaseStatus name={APP_VIEWS.RELEASE_DETAIL} {...props} />}
          />,
          <RouteComponent
            key={6}
            path="/releases/:release_id/edit/:stack_index?/:card_index?"
            render={props => <ManageRelease name={APP_VIEWS.RELEASE_CREATE} {...props} />}
          />,
          ,
        ]}
        <RouteComponent
          key={0}
          path="/statements"
          render={props => <StatementsRouter name={APP_VIEWS.STATEMENTS} {...props} />}
        />
        <RouteComponent path={'/settings'} render={() => <SettingsRouter isPrivate />} />
        <RouteComponent
          path="/permission-denied"
          render={props => (
            <GenericWithCtaHome
              headingMessage="Oops, unauthorized."
              bodyMessage="You stumbled upon something that you don't haver permissions to see."
              {...props}
            />
          )}
        />
        <RouteComponent
          path={'/analytics'}
          render={() => <AnalyticsRouter isPrivate userGroupId={selectedUserGroup.id} />}
        />
        <RouteComponent
          path={'/marketing'}
          render={() => <MarketingRouter isPrivate userGroupId={selectedUserGroup.id} />}
        />
        <RouteComponent
          path={'/marketing-links'}
          render={() => <MarketingLinksRouter isPrivate userGroupId={selectedUserGroup.id} />}
        />
        <RouteComponent
          path={'/marketing-discovery-mode'}
          render={() => <MarketingDiscoveryModeRouter isPrivate userGroupId={selectedUserGroup.id} />}
        />

        <RouteComponent path={'/co-manager'} render={() => <CoManagerFullscreen isPrivate />} />
        <RouteComponent path={'/dsp-uri'} render={() => <GetDspUriRouter isPrivate />} />
        <RouteComponent
          path="/permission-denied"
          render={props => (
            <GenericWithCtaHome
              headingMessage="Oops, unauthorized."
              bodyMessage="You stumbled upon something that you don't haver permissions to see."
              {...props}
            />
          )}
        />
        {/* Redirect basic users to settings */}
        {isUserPayee && !userMustContactSupport && <Redirect to="/settings" />}
        {/* Redirect everyone else to home */}
        {userCanSeeCatalogue && !userMustContactSupport && <Redirect to="/" />}
      </Switch>
    </ErrorBoundary>
  );
}

export default Routes;
