import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

export function Button({ children, variant = 'solid', endIcon, linkColor = 'accent.500', ...props }) {
  if (variant === 'link') {
    return (
      <ChakraButton
        _active={{ backgroundColor: 'transparent' }}
        _hover={{ textDecoration: 'underline' }}
        alignItems="center"
        bg="none"
        border="none"
        color={linkColor}
        display="inline-flex"
        fontFamily="'Manrope', sans-serif"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="600"
        gap={2}
        height="auto"
        justifyContent="center"
        letterSpacing="-0.08px"
        lineHeight="130%"
        p={0}
        textAlign="center"
        textDecoration="none"
        {...props}>
        {children}
        {endIcon}
      </ChakraButton>
    );
  }

  return (
    <ChakraButton
      alignItems="center"
      background="linear-gradient(90deg, #FF335F 0%, #C92A4C 100%)"
      bg="none"
      borderWidth={1}
      borderStyle="solid"
      borderColor="accent.500"
      borderRadius="full"
      color="brown.100"
      display="flex"
      flexDirection="row"
      fontFamily="'Manrope', sans-serif"
      fontSize="16px"
      fontWeight="400"
      justifyContent="center"
      letterSpacing="-0.08px"
      lineHeight="21px"
      minH={12}
      padding="8px 28px"
      textAlign="center"
      _hover={{
        bg: 'none',
        background: 'linear-gradient(90deg, #FF335F 0%, #C92A4C 100%)',
      }}
      _active={{
        bg: 'none',
        background: 'linear-gradient(90deg, #FF335F 0%, #C92A4C 100%)',
      }}
      {...props}>
      {children}
    </ChakraButton>
  );
}
