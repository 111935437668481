import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const AppleIcon = createIcon({
  displayName: 'AppleIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#clip0_46_4188)">
        <path
          d="M17.6202 21.2C16.5362 22.252 15.3402 22.088 14.2002 21.592C12.9882 21.086 11.8802 21.054 10.6002 21.592C9.00622 22.28 8.16022 22.08 7.20022 21.2C1.78022 15.62 2.58022 7.12 8.74022 6.8C10.2342 6.88 11.2802 7.626 12.1602 7.688C13.4682 7.422 14.7202 6.66001 16.1202 6.76001C17.8022 6.896 19.0602 7.56 19.9002 8.754C16.4402 10.834 17.2602 15.394 20.4382 16.674C19.8022 18.344 18.9862 19.994 17.6182 21.214L17.6202 21.2ZM12.0402 6.74001C11.8782 4.26001 13.8882 2.22002 16.2002 2.02002C16.5182 4.88001 13.6002 7.02 12.0402 6.74001Z"
          fill="#FF335F"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_4188">
          <rect width="16.84" height="20" fill="white" transform="translate(3.58008 2)" />
        </clipPath>
      </defs>
    </>
  ),
});
