import { useQuery } from 'react-query';
import * as API from 'utils/API/API';

const Home = API.home();

export const useGetHomeWorkshops = () => {
  return useQuery(['getHomeWorkshops', {}], () => {
    return Home.getWorkshops();
  });
};
