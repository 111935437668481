import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, GridItem, Link } from '@chakra-ui/react';
import { Card } from 'components/card/card';
import { CardTitle } from 'components/card/card-title';
import { ArrowRightIcon, ChevronRightIcon } from 'components/icons';
import { ChevronLeftIcon } from 'components/icons/chevron-left';
import { Button } from 'components/v2/button/button';
import { Typography } from 'components/v2/typography/typography';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { UpcomingEvents } from './upcoming-events';

const NavigationButton = ({ onClick, disabled, children }) => (
  <Box
    as="button"
    width="32px"
    height="32px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    cursor={disabled ? 'default' : 'pointer'}
    opacity={disabled ? 0.5 : 1}
    onClick={!disabled ? onClick : undefined}>
    {children}
  </Box>
);

const EventsAndWorkshopsHeader = ({ swiperRef, updateSwiper }) => (
  <Box display="flex" gap={2} my={2} alignItems="center" justifyContent="space-between">
    <Typography variant="card-label" color="brown.700">
      Recorded Workshops
    </Typography>
    <Box display="flex">
      <NavigationButton
        onClick={() => {
          swiperRef?.slidePrev();
          updateSwiper();
        }}>
        <ChevronLeftIcon color="brown.900" />
      </NavigationButton>
      <NavigationButton
        onClick={() => {
          swiperRef?.slideNext();
          updateSwiper();
        }}>
        <ChevronRightIcon color="brown.900" />
      </NavigationButton>
    </Box>
  </Box>
);

const WorkshopCard = ({ imageUrl, name, title, jobTitle, url }) => (
  <Box bg="brown.100" borderRadius={8} display="flex" flexDir="column" gap={3} flex={1}>
    <Box as="img" src={imageUrl} w="100%" minH="175" maxH={175} objectFit="cover" />
    <Box display="flex" p={2} pb={18} flexDir="column" flex={1} justifyContent="space-between" gap={3}>
      <Box display="flex" flexDir="column" gap={3}>
        <Typography color="accent.500">{name}</Typography>
        <Typography variant="card-title">
          <Link href={url} target="_blank">
            {title}
          </Link>
        </Typography>
      </Box>
      <Typography>{jobTitle}</Typography>
    </Box>
  </Box>
);

const WorkshopsCarousel = ({ swiperRef, setSwiperRef, workshops }) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const updateSwiper = useCallback(() => {
    if (swiperRef) {
      swiperRef.update();
      setIsBeginning(swiperRef.isBeginning);
      setIsEnd(swiperRef.isEnd);
    }
  }, [swiperRef]);

  useEffect(() => {
    if (swiperRef) {
      updateSwiper();
    }
  }, [swiperRef, updateSwiper]);

  return (
    <Card p={4} borderColor="brown.500" bg="brown.300" borderRadius="xl" height="100%">
      <EventsAndWorkshopsHeader
        swiperRef={swiperRef}
        isBeginning={isBeginning}
        isEnd={isEnd}
        updateSwiper={updateSwiper}
      />
      <Swiper
        modules={[A11y]}
        slidesPerView="auto"
        spaceBetween={16}
        centeredSlides={false}
        loop={false}
        freeMode
        onSwiper={swiper => {
          setSwiperRef(swiper);
          updateSwiper();
        }}
        onSlideChange={updateSwiper}
        onTouchEnd={updateSwiper}
        onMouseUp={updateSwiper}>
        {workshops?.map(item => (
          <SwiperSlide key={item.id} style={{ width: '170px', display: 'flex', height: 'auto' }}>
            <WorkshopCard {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Card>
  );
};

const EventsAndWorkshopsContent = ({ swiperRef, setSwiperRef, events, workshops, canAccessEvents }) => (
  <Grid templateColumns="repeat(12, 1fr)" gap={5}>
    <GridItem colSpan={{ base: 12, sm: 4, md: 4, lg: 4 }}>
      <UpcomingEvents events={events} canAccessEvents={canAccessEvents} />
    </GridItem>
    <GridItem colSpan={{ base: 12, sm: 8, md: 8, lg: 8 }}>
      <WorkshopsCarousel workshops={workshops} swiperRef={swiperRef} setSwiperRef={setSwiperRef} />
    </GridItem>
  </Grid>
);

export function EventsAndWorkshops({ events, workshops, canAccessEvents }) {
  const [swiperRef, setSwiperRef] = useState(null);

  return (
    <Card variant="large">
      <CardTitle
        title="Events & Workshops"
        description="Join Feedback Sessions & Watch Workshops"
        flexDir={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}>
        <Button
          variant="link"
          as="a"
          target="_blank"
          href="https://community.venicemusic.co/spaces/14005711/content'"
          linkColor="brown.900"
          endIcon={<ArrowRightIcon />}>
          View all workshops
        </Button>
      </CardTitle>
      <EventsAndWorkshopsContent
        events={events}
        workshops={workshops}
        swiperRef={swiperRef}
        setSwiperRef={setSwiperRef}
        canAccessEvents={canAccessEvents}
      />
    </Card>
  );
}
