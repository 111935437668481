import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import asModal from 'features/Modal/as-modal';
import { Box, Flex, Spacer, HStack, Square, Text, Center, SimpleGrid } from '@chakra-ui/react';
import MessageContainer from '../../components/Co-ManagerMessageContainer/co-manager-message-container';
import UserMessageInput from '../../components/Co-ManagerUserMessageInput/co-manager-user-message-input';
import { chakraTheme } from '../../theme';
import { say, listen } from '../../utils/API/co-manager';
import useMessages from '../../hooks/use-co-manager-messages';
import { useCoManagerMessageAdditivePageQuery } from '../../data-client/use-co-manager-thread-data';
import coManagerLogo from '../../assets/co-manager-logo.svg';
import { usePromptLibrary } from 'hooks/use-prompt-library';
import { RiRefreshLine } from 'react-icons/ri';
import { PromptLibraryLabel } from './prompt-library-label';
import { SuggestedConversationBox } from './suggested-conversation-box';

/**
 * Create a message object
 *
 * @param {string} text
 * @param {import('../../hooks/use-co-manager-messages').MessageType} type
 * @param {number} [timestamp]
 * @returns {import('../../hooks/use-co-manager-messages').MessageWithoutId}
 */
const makeMessage = (text, type, timestamp = Date.now(), metadata = {}) => ({
  text,
  type,
  timestamp,
  ...metadata,
});

const THREAD_RELOAD_CHECK_TIMEOUT_MS = 7 * 60 * 1000;

function CoManagerToolbar({ children, ...props }) {
  return (
    <HStack
      spacing="1rem"
      shouldWrapChildren={false}
      {...props}
      align="start"
      flexShrink={0}
      maxWidth="100%"
      overflowX="auto">
      {children}
    </HStack>
  );
}

/**
 * @type {React.FC<{{ initialMessages: Array<import('../../hooks/use-co-manager-messages').MessageWithoutId>, userInputStyles, children: React.ReactElement, height: string  }}>}
 * @return {JSX.Element}
 */
function CoManagerModal(props) {
  const {
    threadId,
    initialMessages,
    showGuidedPrompts,
    userInputStyles,
    children,
    height = 'calc(100vh - 7.5rem - 320px)',
    setConnectionStatusFn,
    setSuggestions,
    onReloadThread,
    isTrialMode,
    margin,
  } = props;

  const reloadThreadTimeoutRef = useRef();
  const { messages, appendMsg, setTyping, resetList } = useMessages();
  const {
    modelData: oldMessageModelData,
    pageArgs: oldMessagePageArgs,
    isProcessing: isProcessingOldMessageQuery,
    loadNextPage: loadNextOldMessagePage,
    reset: resetOldMessages,
  } = useCoManagerMessageAdditivePageQuery({
    threadId,
    reactQueryArgs: {
      enabled: !!threadId,
    },
  });

  const formattedOldMessages = useMemo(() => {
    return oldMessageModelData.models.map(({ chat_bot_thread_message_id: id, message_content: { role, content } }) => ({
      id,
      type: role,
      text: content,
    }));
  }, [oldMessageModelData]);

  const [sending, setSending] = useState(false);
  const [serverConnectionState, setServerConnectionState] = useState(null);
  const assignedToken = useRef(undefined);
  const sseCleanup = useRef(undefined);

  // We only want to render a single toolbar
  const toolbarChildren = React.Children.toArray(children).find(
    child => React.isValidElement(child) && child.type === CoManagerToolbar
  );

  const connectionStatusCallback = useCallback(setConnectionStatusFn, [setConnectionStatusFn]);

  const onSend = useCallback(
    (userInputText, metadata = {}) => {
      if (!userInputText.startsWith(RATING_INPUT)) {
        setSending(true);
        // Store local message
        appendMsg(makeMessage(userInputText, 'user', undefined, metadata));
      }

      // Send message to the server
      say(userInputText, assignedToken.current, metadata.rating, metadata.guide, threadId, metadata.promptLibrary).then(
        res => {
          try {
            setTyping(true);
            let messageAccumulator = '';

            sseCleanup.current =
              sseCleanup.current ??
              listen(
                res.data.token,
                event => {
                  assignedToken.current = res.data.token;
                  if (event.actor === 'delta') {
                    messageAccumulator += event.text.value;
                    appendMsg(makeMessage(messageAccumulator, 'typing', event.timestamp, event.metadata));
                  } else if (event.actor === 'suggestions') {
                    setSuggestions(JSON.parse(event.text));
                  } else {
                    setTyping(false);
                    messageAccumulator = '';
                    appendMsg(makeMessage(event.text, event.actor, event.timestamp, event.metadata));

                    if (messages.length < 3) {
                      onReloadThread();
                    }
                    clearTimeout(reloadThreadTimeoutRef.current);
                    reloadThreadTimeoutRef.current = setTimeout(onReloadThread, THREAD_RELOAD_CHECK_TIMEOUT_MS);
                    setSending(false);
                  }
                },
                state => {
                  setServerConnectionState(state);
                  connectionStatusCallback(state);
                }
              );
          } catch (error) {
            setSending(false);
            console.error(error);
            appendMsg(
              makeMessage(
                "I'm sorry, your message response could not be processed correctly. Please try again.",
                'ai',
                Date.now(),
                {}
              )
            );
          }
        },
        err => {
          const error = 'Error - Refresh page and try again.';
          setServerConnectionState(error);
          connectionStatusCallback(error);
          setSending(false);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      appendMsg,
      setTyping,
      connectionStatusCallback,
      setSuggestions,
      threadId /*, onReloadThread  messages <- causes infinite loop */,
    ]
  );

  const RATING_INPUT = 'Conversation Rating: ';

  /**
   * Save conversation rating to the back end
   */
  const sendRating = useCallback(
    rating => {
      onSend(`${RATING_INPUT}${rating}`, { rating });
    },
    [onSend]
  );

  // Stop listening for Server-Sent Events after unmount
  useEffect(() => {
    if (initialMessages.length > 0 && initialMessages.at(-1).type === 'user') {
      onSend(initialMessages.at(-1).text, { guide: initialMessages.at(-1).guide });
    }

    // Reset signal
    if (initialMessages.length === 0) {
      // This code will be called when component is removed
      if (typeof sseCleanup.current === 'function') sseCleanup.current();
      sseCleanup.current = undefined;
      assignedToken.current = undefined;
      setSending(false);
      setTyping(false);
      setSuggestions([]);
      resetList();
      resetOldMessages();
      setServerConnectionState('Ready');
      clearTimeout(reloadThreadTimeoutRef.current);
    }

    return () => {
      clearTimeout(reloadThreadTimeoutRef.current);
      // This code will be called when component is removed
      if (typeof sseCleanup.current === 'function') sseCleanup.current();
      sseCleanup.current = undefined;
    };
  }, [initialMessages, onSend, resetList, setSuggestions, resetOldMessages, setTyping]);

  const { data: promptLibraryList } = usePromptLibrary({
    queryKey: 'promptLibrary',
    random: true,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);

  const suggestedConversations = promptLibraryList?.[selectedIndex]?.suggestions || [];

  const handleRefreshClick = () => {
    const next = selectedIndex + 1;
    setSelectedIndex(next >= promptLibraryList?.length ? 0 : next);
  };

  return (
    <Flex flexDirection="column" minH={height} margin={margin}>
      {messages.length === 0 && isProcessingOldMessageQuery === false && !threadId && showGuidedPrompts ? (
        <Box maxW="1150px">
          <Box height="100%">
            <Flex width="100%" pt="0.5rem" alignItems="end" justifyContent="space-between">
              <Text fontWeight="600" color="black80" fontSize="1rem" textAlign="left">
                Suggested Conversations
              </Text>
              <Box
                border="1px solid"
                height="2rem"
                width="2rem"
                borderRadius={30}
                p={1.5}
                textAlign="center"
                borderColor="cream200"
                cursor="pointer"
                onClick={() => handleRefreshClick()}
                mr={1}>
                <RiRefreshLine color="black" size="1.1rem" />
              </Box>
            </Flex>
            <SimpleGrid
              mt="0.7rem"
              columns={{ base: 2, md: 3, lg: 5 }}
              spacing={3}
              width="100%"
              flexWrap="wrap"
              display={'flex'}>
              {suggestedConversations?.map(conversation => (
                <SuggestedConversationBox
                  key={conversation}
                  title={conversation}
                  isTrialMode={isTrialMode}
                  onClick={() => {
                    if (isTrialMode) {
                      window.location.href = `/settings#membership`;
                      return;
                    }
                    onSend(conversation, { promptLibrary: true });
                  }}
                />
              ))}
            </SimpleGrid>
            <Box w="100%">
              <Text fontWeight="600" pt="1.5rem" color="black80" fontSize="1rem" textAlign="left">
                Prompt Library
              </Text>
            </Box>
            <Flex width="100%" gap={3} flexWrap="wrap" mt="0.7rem">
              {promptLibraryList?.map((prompt, i) => (
                <PromptLibraryLabel
                  label={prompt.name}
                  onClick={() => setSelectedIndex(i)}
                  isSelected={selectedIndex === i}
                />
              ))}
            </Flex>
          </Box>
        </Box>
      ) : threadId && isProcessingOldMessageQuery && messages.length === 0 && formattedOldMessages.length === 0 ? (
        <Center style={{ width: '100%', height: '100%' }}>Loading Previous Conversation</Center>
      ) : (
        <MessageContainer
          messages={messages}
          prefixMessages={formattedOldMessages}
          hasMoreMessageHistory={oldMessagePageArgs.hasMoreData}
          isLoadingMessageHistory={isProcessingOldMessageQuery}
          onLoadMoreMessages={loadNextOldMessagePage}
          onRating={sendRating}></MessageContainer>
      )}
      <Flex flexDirection="row" paddingBlock="1rem" alignItems="flex-start" w="100%" overflow="hidden">
        {toolbarChildren ? (
          <>
            {toolbarChildren}
            <Spacer />
          </>
        ) : null}
      </Flex>
      <Flex maxW={1150} width="100%">
        <Square
          width="56px"
          height="56px"
          border="3px solid"
          borderColor="cream100"
          borderRadius="50%"
          marginRight="1rem">
          <img src={coManagerLogo} style={{ width: '40px', height: '40px' }} alt="" />
        </Square>
        <Box
          borderTop="3px"
          borderTopColor={chakraTheme.colors.gray30}
          borderTopStyle="solid"
          p="7px 16px 7px 20px"
          width="100%"
          {...userInputStyles}>
          <UserMessageInput
            onSend={onSend}
            disabled={sending || (isTrialMode && messages.length === 0)}></UserMessageInput>
        </Box>
      </Flex>
      <Flex maxW={1150} width="100%" pt={3}>
        {serverConnectionState ? (
          <Text color={serverConnectionState.includes('Error') ? 'red100' : 'green100'}>{serverConnectionState}</Text>
        ) : (
          <Text color="green100">Ready</Text>
        )}
        <Text color="black70">&nbsp;· Co-Manager Version 1.1.beta</Text>
      </Flex>
    </Flex>
  );
}

export default asModal(CoManagerModal);
export { CoManagerModal as CoManagerInline, makeMessage, CoManagerToolbar };
