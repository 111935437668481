import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const VeniceIcon = createIcon({
  displayName: 'VeniceIcon',
  viewBox: '0 0 25 26',
  defaultProps: {
    width: '25px',
    height: '26px',
    fill: 'none',
  },
  path: (
    <>
      <g clipPath="url(#clip0_154_4674)">
        <path
          d="M12.2229 9.28846C11.2149 10.7351 9.95272 11.9945 8.50877 13.0026C9.9554 14.0108 11.2149 15.2728 12.2229 16.7168C13.231 15.2702 14.4931 14.0108 15.9371 13.0026C14.4905 11.9945 13.231 10.7324 12.2229 9.28846ZM12.2229 0.782469C12.2229 7.53162 17.694 13.0053 24.4458 13.0053C17.6966 13.0053 12.2229 18.4763 12.2229 25.2281C12.2229 18.479 6.75193 13.0053 0.000177154 13.0053C6.74923 13.0053 12.2229 7.5343 12.2229 0.782469Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_154_4674">
          <rect width="24.4456" height="24.4456" fill="white" transform="translate(0 0.782471)" />
        </clipPath>
      </defs>
    </>
  ),
});
