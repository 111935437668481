import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SpotifyIcon = createIcon({
  displayName: 'SpotifyIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M11.9999 2C6.47724 2 2 6.47723 2 12.0001C2 17.5231 6.47724 22 11.9999 22C17.5232 22 22 17.5231 22 12.0001C22 6.47759 17.5234 2 11.9999 2Z"
        fill="#1ED760"
      />
      <path
        d="M15.7285 16.6295C16.0222 16.8098 16.4067 16.7167 16.5858 16.4229C16.7662 16.1292 16.673 15.7459 16.3793 15.5655C13.7434 13.9546 10.4759 13.5799 6.66663 14.4502C6.33037 14.5266 6.12104 14.861 6.19782 15.1966C6.27424 15.5321 6.6086 15.7423 6.94403 15.6659C10.4249 14.8705 13.3806 15.1954 15.7285 16.6295Z"
        fill="#FFFAF2"
      />
      <path
        d="M16.7375 13.957C17.1041 14.1827 17.5841 14.0669 17.8098 13.7C18.0355 13.3339 17.9197 12.854 17.5531 12.6283C14.4663 10.7314 9.95142 10.1976 6.31974 11.2996C5.90812 11.4249 5.67563 11.8596 5.79982 12.272C5.92496 12.6836 6.36046 12.916 6.77279 12.7914C9.95214 11.8263 14.0495 12.3048 16.7375 13.957Z"
        fill="#FFFAF2"
      />
      <path
        d="M6.29741 9.70823C9.37454 8.77429 14.692 8.9503 17.9149 10.8646C18.3579 11.1286 18.934 10.982 19.1967 10.5376C19.4606 10.0937 19.3149 9.51968 18.8695 9.25589C15.1589 7.05304 9.28678 6.84585 5.75444 7.9182C5.25995 8.06842 4.98125 8.59063 5.13099 9.085C5.28073 9.57914 5.80328 9.8581 6.29741 9.70823Z"
        fill="#FFFAF2"
      />
    </>
  ),
});
