import React, { useMemo } from 'react';
import { Button, Text, Wrap } from '@chakra-ui/react';
import { TierCard } from './tier-card';
import MEMBERSHIP from '../../constants/MEMBERSHIP.gen.json';
import INTERVAL from '../../constants/INTERVAL.gen.json';
import { chakraTheme } from 'theme';
import SubSection from '../../settings/sub-section';
import SectionHeaderBar from 'settings/section-header-bar';
import Box from 'components/Box/Box';

const { SUBSCRIPTION_TIER_RANK_ORDER } = MEMBERSHIP;

const DiscountCouponInfo = ({ subscriptionDiscountCoupon, isValid }) => {
  return subscriptionDiscountCoupon && isValid ? <Box>Promo Code: {subscriptionDiscountCoupon}</Box> : <></>;
};

export function PaymentHeader({
  cancelAtPeriodEnd,
  currentPeriodEnd,
  isCanceled,
  isInTrial,
  onStripeEmailBlockList,
  subscriptionInterval,
  subscriptionAmount,
  subscriptionAmountWithDiscount,
  subscriptionDiscountCoupon,
}) {
  const message = useMemo(() => {
    if (onStripeEmailBlockList) {
      return (
        <>
          This subscription is active and is set to expire on {currentPeriodEnd}. To inquire about membership renewal,
          contact Venice Support.
        </>
      );
    } else if (isCanceled) {
      return <>This subscription is not active.</>;
    } else if (cancelAtPeriodEnd) {
      return (
        <>
          Subscription Expires <b>{currentPeriodEnd}</b>.
        </>
      );
    } else if (isInTrial) {
      return (
        <>
          Your trial will convert to a full membership on <b>{currentPeriodEnd}</b> at the{' '}
          {INTERVAL[subscriptionInterval].long.toLowerCase()} price of{' '}
          <b>
            ${subscriptionAmountWithDiscount}/{INTERVAL[subscriptionInterval].short.toUpperCase()}
          </b>
          .
          <DiscountCouponInfo
            subscriptionDiscountCoupon={subscriptionDiscountCoupon}
            isValid={subscriptionAmount > subscriptionAmountWithDiscount}
          />
        </>
      );
    } else {
      return (
        <>
          This membership will renew on <b>{currentPeriodEnd}</b> at the{' '}
          {INTERVAL[subscriptionInterval].long.toLowerCase()} price of{' '}
          <b>
            ${subscriptionAmountWithDiscount}/{INTERVAL[subscriptionInterval].short.toUpperCase()}
          </b>
          .{' '}
          <DiscountCouponInfo
            subscriptionDiscountCoupon={subscriptionDiscountCoupon}
            isValid={subscriptionAmount > subscriptionAmountWithDiscount}
          />
        </>
      );
    }
  }, [
    onStripeEmailBlockList,
    isCanceled,
    cancelAtPeriodEnd,
    isInTrial,
    currentPeriodEnd,
    subscriptionInterval,
    subscriptionAmount,
    subscriptionAmountWithDiscount,
    subscriptionDiscountCoupon,
  ]);

  return (
    <Text
      variant="pageInstructions"
      paddingBottom="1em"
      fontSize="lg"
      fontWeight="normal"
      color={chakraTheme.colors.black100}
      mt={{ base: '1.25rem', lg: '2.5rem' }}>
      {message}
    </Text>
  );
}

export const SubscriptionInfo = ({ stripeSubscription, handleUpgradeRequest, tiers, allowTierUpgrades = true }) => {
  // Current tier may not be part of the list of tiers available for purchase. Do not rely on it being in the tiers list.
  const { tier: tierName, isInTrial } = stripeSubscription;

  const currentSubscriptionRank = SUBSCRIPTION_TIER_RANK_ORDER[tierName];
  let possibleUpgradeTiers;

  if (isInTrial) {
    possibleUpgradeTiers = [
      {
        price: stripeSubscription.subscriptionMembership.stripe_subscription_record.plan.amount / 100,
        couponPrice: undefined,
        title: stripeSubscription.subscriptionMembership.stripe_subscription_record.plan.nickname,
        description: stripeSubscription.subscriptionMembership.stripe_subscription_record.plan.metadata.benefits,
        tierName: stripeSubscription.subscriptionMembership.stripe_subscription_record.plan.metadata.tier,
        interval:
          stripeSubscription.subscriptionMembership.stripe_subscription_record.plan.interval +
          ':' +
          stripeSubscription.subscriptionMembership.stripe_subscription_record.plan.interval_count,
      },
    ];
  } else {
    possibleUpgradeTiers = tiers
      .map(t => {
        const rank = SUBSCRIPTION_TIER_RANK_ORDER[t.tierName];

        return {
          ...t,
          rank,
        };
      })
      .filter(t => {
        // Do not allow upgrades when rank is unknown, when rank is below the current tier, or to trial plans
        return t.rank !== undefined && currentSubscriptionRank < t.rank && t.trialPrice === undefined;
      });
    possibleUpgradeTiers.sort((a, b) => a.rank - b.rank);
  }

  return allowTierUpgrades && possibleUpgradeTiers.length > 0 ? (
    <SubSection>
      <SectionHeaderBar name={isInTrial ? 'Unlock all features now' : 'Upgrade Today'}></SectionHeaderBar>
      {isInTrial ? (
        <Text variant="pageInstructions" paddingBottom="1em" paddingTop="1em" fontSize="lg" fontWeight="normal">
          Tap to upgrade to a full membership and unlock all features. Your trial will convert to a full membership
          immediately.
        </Text>
      ) : (
        <Text variant="pageInstructions" paddingBottom="1em" paddingTop="1em" fontSize="lg" fontWeight="normal">
          Select a plan below to see the final price with prorated discount applied.
        </Text>
      )}
      <Wrap spacing="1.5rem" align="left">
        {possibleUpgradeTiers.map(upgradeTier => (
          <Box>
            <TierCard
              w={{ xs: '100%', md: '450px' }}
              key={upgradeTier.tierName}
              tier={upgradeTier}
              isSelected={false}
            />
            <Button
              borderRadius="full"
              background={chakraTheme.colors.pink}
              color={chakraTheme.colors.white}
              mt={'1rem'}
              onClick={t => handleUpgradeRequest(t, upgradeTier.interval, isInTrial)}
              _hover={{ opacity: 0.8 }}>{`Upgrade to ${upgradeTier.title}`}</Button>
          </Box>
        ))}
      </Wrap>
    </SubSection>
  ) : null;
};
