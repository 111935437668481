import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import CoManagerAIIcon from 'features/Co-manager/co-manager-ai-icon';
import icons from '../../utils/icons';

export const SuggestedConversationBox = ({ title, onClick, isTrialMode }) => {
  return (
    <Flex
      border="1px solid"
      borderRadius={10}
      p="10px"
      borderColor="cream200"
      w="100%"
      maxW={{ base: '48%', md: 220, lg: 220 }}
      cursor="pointer"
      flexDirection="column"
      justifyContent="space-between"
      color="black70"
      position="relative"
      opacity={isTrialMode ? '0.6' : 1}
      onClick={onClick}>
      <Box mb="0.5em">
        {isTrialMode && <icons.unlock style={{ position: 'absolute', top: 15, right: 15 }} />}
        <CoManagerAIIcon style={{ height: '2rem', width: '2rem' }} />
      </Box>
      <Box>{title}</Box>
    </Flex>
  );
};
