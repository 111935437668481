import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CoManagerAIIcon from 'features/Co-manager/co-manager-ai-icon';
import { CoManagerInline, CoManagerToolbar, makeMessage } from 'features/Co-manager/co-manager-modal-inline';
import {
  Text,
  Button,
  Flex,
  Link,
  VStack,
  Box,
  useTheme,
  IconButton,
  Heading,
  Center,
  Spinner,
} from '@chakra-ui/react';
import FullscreenThreadPreviewList from 'features/Co-manager/fullscreen-thread-preview-list';
import { RiMenuLine, RiMenuFoldLine, RiArrowLeftLine, RiMessage3Line, RiArrowRightSLine } from 'react-icons/ri';
import { useNaturalTimeCategorizedModels } from '../../hooks/use-categorized-models';
import { useHistory } from 'react-router-dom';
import {
  useCoManagerThreadAdditivePageQuery,
  useCoManagerDeleteThreadMutation,
} from '../../data-client/use-co-manager-thread-data';
import { useUserTierChecks } from '../../hooks/authorization-hooks';

const defaultPrompts = [
  'How do I get on a playlist?',
  'How do I increase my monthly listeners on Spotify?',
  'How do I grow my YouTube Music audience?',
];

export default function CoManagerFullscreen() {
  const history = useHistory();
  const location = useLocation();

  const [chatArgs, setChatArgs] = useState({ initialMessages: [], threadId: undefined });
  const [connectionStatus, setConnectionStatus] = useState(0);
  const [suggestions, setSuggestions] = useState(defaultPrompts);
  const [menuOpen, setMenuOpen] = useState(false);
  const { canAccessCoManager } = useUserTierChecks();

  const theme = useTheme();
  const {
    fetchPage: fetchThreadPage,
    modelData: threadData,
    isProcessing: isProcessingThreadDataOperation,
    loadNextPage: loadNextThreadPage,
    removeLocalModel: removeLocalThread,
    pageArgs,
  } = useCoManagerThreadAdditivePageQuery();

  const { categories: threadModelCategories } = useNaturalTimeCategorizedModels({ models: threadData.models });

  const openThread = useCallback(thread => {
    setChatArgs({ initialMessages: [], threadId: thread ? thread.chat_bot_thread_id : undefined });
    setMenuOpen(false);
  }, []);

  const { mutate: deleteThreadMutate } = useCoManagerDeleteThreadMutation({
    onSuccess: (queryFunc, threadId) => {
      removeLocalThread(threadId);

      if (threadId === chatArgs.threadId) {
        openThread();
      }
    },
  });

  const sendPrompt = prompt => {
    setChatArgs({
      initialMessages: [makeMessage(prompt, 'user', Date.now())],
      threadId: undefined,
    });
    setMenuOpen(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const prompt = searchParams?.get('prompt');

    if (prompt && canAccessCoManager) {
      sendPrompt(prompt);
    }
  }, [location, canAccessCoManager]);

  return (
    <Box height={'100vh'}>
      <Flex flexGrow="1" h={'100%'} flexDirection={{ base: 'row', lg: 'row-reverse' }}>
        <Box
          flexGrow="1"
          maxWidth={{ base: '100%', lg: 'calc(100% - 20rem)' }}
          position="relative"
          h={'100%'}
          p={{ base: 4, md: 12 }}
          pt={{ base: 10, md: 12 }}
          pb={{ base: 20, md: 12 }}>
          <CoManagerInline
            threadId={chatArgs.threadId}
            initialMessages={chatArgs.initialMessages}
            height={'100%'}
            showGuidedPrompts
            userInputStyles={{
              border: '1px solid',
              borderColor: 'cream200',
              width: '100%',
              borderRadius: '2rem',
            }}
            onReloadThread={
              //We can fetch page 0 because threads with new activity should be moved to the top of the order
              () => fetchThreadPage(0)
            }
            setConnectionStatusFn={setConnectionStatus}
            setSuggestions={setSuggestions}
            isTrialMode={!canAccessCoManager}>
            <CoManagerToolbar pb="0.2rem">
              {/* Initial prompts */}
              {chatArgs.initialMessages.length === 0 &&
                suggestions.map(prompt => (
                  <Button
                    key={prompt}
                    flexShrink={0}
                    onClick={() => sendPrompt(prompt)}
                    variant="outline"
                    fontSize="0.85rem">
                    {prompt}
                  </Button>
                ))}
            </CoManagerToolbar>
          </CoManagerInline>
          <Flex display={{ lg: 'none' }} position="absolute" top="0" bg="white">
            <IconButton
              icon={<RiMenuLine />}
              m="0.25rem"
              variant="smallCream"
              onClick={() => setMenuOpen(!menuOpen)}></IconButton>
          </Flex>
        </Box>
        <VStack
          sx={{ transition: 'left .25s', color: 'white' }}
          position={{ base: 'fixed', lg: 'relative' }}
          left={{ base: menuOpen ? '0rem' : '-20rem', lg: '0rem' }}
          height={'100%'}
          width={'20rem'}
          backgroundColor={theme.colors.black90}>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Box>
              <Button
                leftIcon={<RiArrowLeftLine />}
                variant={'transparent'}
                color={theme.colors.white}
                onClick={() => history.push('/')}>
                Exit
              </Button>
            </Box>
            <Box visibility={{ lg: 'hidden' }}>
              <IconButton
                icon={<RiMenuFoldLine />}
                m="0.25rem"
                mr="1rem"
                variant="smallCream"
                backgroundColor="white"
                onClick={() => setMenuOpen(!menuOpen)}></IconButton>
            </Box>
          </Flex>
          <Flex alignItems="center" gap=".5rem" pl=".5rem" width="100%">
            <CoManagerAIIcon style={{ height: '2.5rem', width: '2.5rem' }} />
            <Heading as={'h3'} fontSize={'1.5rem'} color="white" fontFamily="Neue Machina">
              Co·Manager
            </Heading>
          </Flex>
          <Text fontSize=".8rem" pl=".5rem">
            Co-Manager is your AI music assistant that combines strategies from top artist managers and marketers with
            OpenAI machine learning to offer personalized guidance to power your music career.
          </Text>
          <Box width="100%" pl=".5rem">
            <Link
              isExternal
              color="pink"
              href="https://support.venice.tech/distribution/co-manager-ai-powered-music-career-assistant">
              <Flex alignItems="center" fontSize=".8rem">
                View Query Best Practices <RiArrowRightSLine />
              </Flex>
            </Link>
          </Box>
          {threadData.models.length > 0 && (
            <>
              <Box w="100%" flexGrow="1" margin="0" overflow="auto" pt="1rem" pr="1rem" pl=".5rem">
                <FullscreenThreadPreviewList
                  threadModelCategories={threadModelCategories}
                  highlightedThreadId={chatArgs.threadId}
                  onOpenThread={openThread}
                  onDeleteThread={thread => deleteThreadMutate(thread.chat_bot_thread_id)}
                />
                {pageArgs.hasMoreData && (
                  <Center height="2rem">
                    {isProcessingThreadDataOperation ? (
                      <Spinner />
                    ) : (
                      <Button variant="transparent" color="white" onClick={loadNextThreadPage}>
                        Load More Conversations
                      </Button>
                    )}
                  </Center>
                )}
              </Box>
            </>
          )}
          <Box height="10rem" pt="2.25rem" width="100%" pl=".5rem" pr=".5rem">
            <Button
              width="100%"
              variant="outline"
              _hover={{ color: 'black.100', backgroundColor: 'white' }}
              onClick={() => openThread()}>
              <Flex alignItems="bottom">
                <RiMessage3Line />{' '}
                <Text ml=".5rem" fontSize=".9rem">
                  Start a New Conversation
                </Text>
              </Flex>
            </Button>
          </Box>
        </VStack>
      </Flex>
    </Box>
  );
}
