import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const SearchIcon = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    width: '24px',
    height: '24px',
    fill: 'none',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 11.5C21.25 6.66751 17.3325 2.75 12.5 2.75C7.66751 2.75 3.75 6.66751 3.75 11.5C3.75 16.3325 7.66751 20.25 12.5 20.25C17.3325 20.25 21.25 16.3325 21.25 11.5ZM12.5 1.25C18.1609 1.25 22.75 5.83908 22.75 11.5C22.75 17.1609 18.1609 21.75 12.5 21.75C6.83908 21.75 2.25 17.1609 2.25 11.5C2.25 5.83908 6.83908 1.25 12.5 1.25ZM4.53033 19.4697C4.82322 19.7626 4.82322 20.2374 4.53033 20.5303L2.53033 22.5303C2.23744 22.8232 1.76256 22.8232 1.46967 22.5303C1.17678 22.2374 1.17678 21.7626 1.46967 21.4697L3.46967 19.4697C3.76256 19.1768 4.23744 19.1768 4.53033 19.4697Z"
      fill="currentColor"
    />
  ),
});
