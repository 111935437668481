import React from 'react';
import { Drawer, DrawerOverlay, DrawerContent, DrawerBody, DrawerHeader } from '@chakra-ui/react';
import { NavSidebarList, NavSidebarListItem, useNavbarItems } from '../nav-sidebar';
import Box from 'components/Box/Box';
import { NavSidebarLogo } from '../nav-sidebar-logo';

export function NavSidebarMobile({ isOpen, closeMenu }) {
  const { NAV_BOTTOM_ITEMS, NAV_ITEMS } = useNavbarItems();
  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={closeMenu}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Box borderBottomWidth={1} borderColor="brown.400" px={6} py={7}>
              <NavSidebarLogo />
            </Box>
          </DrawerHeader>
          <DrawerBody>
            <NavSidebarList px={0} py={0} pb={7}>
              {NAV_ITEMS.map(({ icon: Icon, label, ...rest }) => (
                <NavSidebarListItem key={label} {...rest}>
                  <Icon boxSize={4} color="brown.700" /> {label}
                </NavSidebarListItem>
              ))}
            </NavSidebarList>
            <NavSidebarList px={0} py={0}>
              {NAV_BOTTOM_ITEMS.map(({ label, icon: Icon, ...rest }) => (
                <NavSidebarListItem key={label} arrowIcon={false} {...rest}>
                  <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                    {label} <Icon color="brown.700" />
                  </Box>
                </NavSidebarListItem>
              ))}
            </NavSidebarList>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
