import React from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Card } from 'components/card/card';
import { CardTitle } from 'components/card/card-title';
import { Typography } from 'components/v2/typography/typography';
import {
  CalendarIcon,
  ChartIcon,
  ChevronRightIcon,
  Headphones,
  LightbulbIcon,
  LinkIcon,
  MusicNoteIcon,
  NotebookLink,
  PenIcon,
  QuestionCircleIcon,
} from 'components/icons';
import { UserHeartIcon } from 'components/icons';
import card1 from '../../../assets/photos/card-1.png';
import card2 from '../../../assets/photos/card-2.png';

const data = [
  {
    id: 1,
    title: 'Schedule Your First Release',
    description: 'Get your music live on all major platforms.',
    icon: <CalendarIcon boxSize={4} color="brown.900" />,
    url: '/manage',
  },
  {
    id: 2,
    title: 'Plan Your Release Strategy',
    description: 'Step-by-step plan for your release.',
    icon: <NotebookLink boxSize={4} color="brown.900" />,
    url: '/co-manager?prompt=Plan Your Release Strategy',
  },
  {
    id: 3,
    title: 'Write Your Artist Bio With Co-Manager',
    description: 'Craft a bio that tells your unique story.',
    icon: <PenIcon boxSize={4} color="brown.900" />,
    url: '/co-manager?prompt=Write Your Artist Bio With Co-Manager',
  },
  {
    id: 4,
    title: 'RSVP To An Expert Workshop',
    description: 'Learn industry skills to level up your career.',
    icon: <LightbulbIcon boxSize={4} color="brown.900" />,
    externalUrl: 'https://community.venicemusic.co/spaces/14005711/content',
  },
  {
    id: 5,
    title: 'Join a Feedback Session',
    description: 'Get guidance from experts before you release.',
    icon: <Headphones boxSize={4} color="brown.900" />,
    externalUrl: 'https://community.venicemusic.co',
  },
  {
    id: 6,
    title: 'Make Connections in the Community',
    description: 'Find collaborators and mentors.',
    icon: <UserHeartIcon boxSize={4} color="brown.900" />,
    externalUrl: 'https://community.venicemusic.co',
  },
  {
    id: 7,
    title: 'Find More Listeners',
    description: 'Try Spotify Discovery Mode.',
    icon: <MusicNoteIcon boxSize={4} color="brown.900" />,
    url: '/marketing-discovery-mode',
  },
  {
    id: 8,
    title: 'Need Help? Reach out to our Team',
    description: 'Get help from a team that understands artists.',
    icon: <QuestionCircleIcon boxSize={4} color="brown.900" />,
    externalUrl: 'https://support.venice.tech',
  },
  {
    id: 9,
    title: 'Create Your Pre-Save Link',
    description: 'Encourage pre-saves and collect emails.',
    icon: <LinkIcon boxSize={4} color="brown.900" />,
    url: '/marketing-links',
  },
  {
    id: 10,
    title: 'See How Your Music Is Performing',
    description: 'Check real-time streams and listener trends.',
    icon: <ChartIcon boxSize={4} color="brown.900" />,
    url: '/analytics',
  },
];

const GuidanceItem = ({ title, description, icon, url, externalUrl }) => {
  const isExternal = Boolean(externalUrl);
  const linkProps = isExternal
    ? { as: 'a', href: externalUrl, target: '_blank', rel: 'noopener noreferrer' }
    : { as: Link, to: url };

  return (
    <Card
      py={5}
      px={4}
      borderRadius="xl"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={3}
      cursor="pointer"
      {...linkProps}>
      {icon}
      <Box display="flex" gap="5px" flexDir="column" flex={1}>
        <Typography variant="card-title" fontWeight="600">
          {title}
        </Typography>
        <Typography variant="body-small">{description}</Typography>
      </Box>
      <ChevronRightIcon />
    </Card>
  );
};

const GuidanceList = ({ items }) => (
  <Grid
    as={Card}
    bg="#E7DFCD"
    borderWidth={1}
    borderStyle="solid"
    borderColor="brown.500"
    borderRadius="xl"
    p={4}
    templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
    gap={4}>
    {items.map(item => (
      <GridItem key={item.id}>
        <GuidanceItem {...item} />
      </GridItem>
    ))}
  </Grid>
);

const GuidanceImages = () => (
  <Box display="flex" flexDir={{ base: 'column', sm: 'column' }} gap={4} justifyContent="center" alignItems="center">
    <Box
      as="img"
      src={card1}
      alt="Guidance Image 1"
      width={{ base: '100%', sm: '263px' }}
      height="auto"
      objectFit="cover"
    />
    <Box
      as="img"
      src={card2}
      alt="Guidance Image 2"
      width={{ base: '100%', sm: '263px' }}
      height="auto"
      objectFit="cover"
    />
  </Box>
);

export function GuidanceAtEveryStep() {
  return (
    <Card variant="large">
      <CardTitle
        title="Guidance at Every Step"
        description="Reach your audience with tools built by industry experts"
      />
      <Grid templateColumns={{ base: '1fr', sm: '1fr 263px' }} gap={4}>
        <GridItem>
          <GuidanceList items={data} />
        </GridItem>
        <GridItem>
          <GuidanceImages />
        </GridItem>
      </Grid>
    </Card>
  );
}
