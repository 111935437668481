import React from 'react';
import { Widget } from './widget';
import { Box } from '@chakra-ui/react';

export function TotalStreams({ totalStreams = 0, data, chartSeries, dateRangeString }) {
  return (
    <Widget title="Total Streams" subtitle={dateRangeString}>
      <Box display="flex" flexDir="column" gap={7}>
        <Widget.Chart bg="brown.100" flexDir="column">
          <Widget.TotalStreamsChart data={chartSeries} totalStreams={totalStreams} />
        </Widget.Chart>
        <Widget.ItemList data={data} isNumbered />
        <Widget.ViewReportButton />
      </Box>
    </Widget>
  );
}
