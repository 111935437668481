import React, { useEffect, useState, useMemo } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { DateTime } from 'luxon';

import {
  Select,
  Heading,
  useTheme,
  Button as ChakraButton,
  SimpleGrid as Grid,
  GridItem,
  Box,
  CircularProgress,
  Link,
} from '@chakra-ui/react';

import * as API from 'utils/API/API';

import ViewWrapper from '../ViewWrapper/view-wrapper';
import Button from 'components/Button/button';
import { TIMEFRAME_OPTIONS } from 'constants/dictionaries';
import StatementsList from './statements-list';
import { usePaymentAccounts } from '../../data-client/user';
import { PAYEE_TYPES } from '../../data-client/user';
import useCurrentUser from '../../data-client/use-current-user';
import Well from 'components/Well/well';
import * as PropTypes from 'prop-types';

import StatementsHeader from './statements-header';

const Payment = API.payment();

const NUM_RESULTS_PAGE_SIZE = 6;

ChakraButton.propTypes = {
  borderColor: PropTypes.string,
  onClick: PropTypes.func,
  borderRadius: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.any,
  children: PropTypes.node,
};

const PayeeStatementContainer = ({ ...props }) => {
  const [user] = useCurrentUser();
  const [payeeType, setPayeeType] = useState(PAYEE_TYPES.USER);
  const [isLabelAdmin, setIsLabelAdmin] = useState(false);

  const [account, setAccount] = useState();
  const [royaltyTransferSummary, setRoyaltyTransferSummary] = useState(null);
  const [royaltyTransfers, setRoyaltyTransfers] = useState(null);

  const [dateRange, setDateRange] = useState(TIMEFRAME_OPTIONS.YTD.value);
  const { paymentAccounts: accounts, paymentAccountsIsLoading } = usePaymentAccounts();

  const payeeTypeAccountMap = useMemo(
    () =>
      accounts.reduce((acc, account) => {
        acc[account.payeeType] = account;
        return acc;
      }, {}),
    [accounts]
  );

  const royaltyTransferSummaryQuery = useRoyaltyTransferSummary(account, getDateRange(dateRange));
  const royaltyTransferQuery = useRoyaltyTransfers(account, getDateRange(dateRange), payeeType);

  useEffect(() => {
    setIsLabelAdmin(user?.currentGroup?.role === 'Label Admin');
  }, [user?.currentGroup?.role]);

  useEffect(() => {
    const accountToSet = payeeTypeAccountMap[payeeType];

    //allows us to set a different payee type in the event that we are missing the default.
    if (!accountToSet) {
      const payeeTypeAccounts = Object.keys(payeeTypeAccountMap);
      const firstPayeeAccountType = payeeTypeAccounts[0];

      //Should prevent infinite render loops in case 0 payee accounts show up.
      if (firstPayeeAccountType && payeeType !== firstPayeeAccountType) {
        setPayeeType(firstPayeeAccountType);
      }
    }

    setAccount(accountToSet);
  }, [accounts, payeeType, payeeTypeAccountMap]);

  useEffect(() => {
    if (royaltyTransferSummaryQuery.isLoading) {
      return;
    }

    if (royaltyTransferSummaryQuery.isSuccess) {
      const { transferred_amount, pending_transfer_amount } = royaltyTransferSummaryQuery.data;
      setRoyaltyTransferSummary({
        total: transferred_amount,
        pending_transfer_amount,
      });
    }
  }, [royaltyTransferSummaryQuery.isLoading, royaltyTransferSummaryQuery.data, payeeType]);

  useEffect(() => {
    if (royaltyTransferQuery.isLoading) {
      return;
    }

    if (royaltyTransferQuery.isSuccess) {
      setRoyaltyTransfers(royaltyTransferQuery.data);
    }
  }, [royaltyTransferQuery?.isLoading, royaltyTransferQuery.isSuccess, royaltyTransferQuery?.data, payeeType]);

  return (
    <Statements
      {...props}
      payeeType={payeeType}
      setPayeeType={setPayeeType}
      allowUserPayeeTab={!!payeeTypeAccountMap[PAYEE_TYPES.USER]}
      allowLabelPayeeTab={!!payeeTypeAccountMap[PAYEE_TYPES.GROUP]}
      isLabelAdmin={isLabelAdmin}
      dateRange={dateRange}
      setDateRange={setDateRange}
      royaltyTransferSummary={royaltyTransferSummary}
      royaltyTransfers={royaltyTransfers}
      paymentAccountsIsLoading={paymentAccountsIsLoading}
      royaltyTransferQuery={royaltyTransferQuery}
      account={account}
    />
  );
};

const Statements = ({
  payeeType,
  setPayeeType,
  isLabelAdmin,
  allowUserPayeeTab,
  allowLabelPayeeTab,
  dateRange,
  setDateRange,
  paymentAccountsIsLoading,
  royaltyTransferSummary,
  royaltyTransfers,
  royaltyTransferQuery,
  account,
  ...props
}) => {
  const theme = useTheme();

  const TimeframeFilter = (
    <Select
      borderColor={theme.colors.black100}
      value={dateRange}
      onChange={e => {
        e.preventDefault();
        setDateRange(e.target.value);
      }}>
      {Object.values(TIMEFRAME_OPTIONS).map((t, i) => (
        <option key={i} value={t.value}>
          {t.label}
        </option>
      ))}
    </Select>
  );

  const defaultButtonGroupStyling = {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    fontSize: '.75rem',
    color: theme.colors.black100,
  };

  const selectedButtonStyling = {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    fontSize: '.75rem',
    background: theme.colors.black100,
    color: 'white',
  };

  const liSpacing = { marginBottom: '.75rem' };

  const changeFromPayeeType = currentType => {
    if (currentType === PAYEE_TYPES.GROUP) {
      setPayeeType(PAYEE_TYPES.USER);
    }
    if (currentType === PAYEE_TYPES.USER) {
      setPayeeType(PAYEE_TYPES.GROUP);
    }
  };

  const PersonalOrLabelButtons = (
    <Box display="inline-flex">
      {allowUserPayeeTab && (
        <ChakraButton
          variant={'unstyled'}
          borderColor={'1px solid black100'}
          borderRight="none"
          borderRadius=".25rem 0 0 .25rem"
          style={payeeType === PAYEE_TYPES.USER ? { ...selectedButtonStyling } : { ...defaultButtonGroupStyling }}
          onClick={() => changeFromPayeeType(PAYEE_TYPES.GROUP)}>
          Personal
        </ChakraButton>
      )}
      {allowLabelPayeeTab && (
        <ChakraButton
          variant={'unstyled'}
          borderColor={'1px solid black100'}
          borderRadius="0 .25rem .25rem 0"
          style={payeeType === PAYEE_TYPES.GROUP ? { ...selectedButtonStyling } : { ...defaultButtonGroupStyling }}
          onClick={() => changeFromPayeeType(PAYEE_TYPES.USER)}>
          Label
        </ChakraButton>
      )}
    </Box>
  );

  const stripeAccountNotConnected =
    (paymentAccountsIsLoading && account) !== null ? false : !account?.stripe_account_id;

  return (
    <ViewWrapper alignWithNav designLayer={0} disableHorizontalScroll={true} {...props}>
      <Grid columns={{ sm: 1, md: 4 }} marginBottom="200px" spacing={4}>
        <GridItem colSpan={{ sm: 1, md: 3 }}>
          <StatementsHeader
            account={account}
            royaltyTransferSummary={royaltyTransferSummary}
            TimeframeFilter={TimeframeFilter}
            payeeType={payeeType}
            setPayeeType={setPayeeType}
            isLabelAdmin={isLabelAdmin}
            PersonalOrLabelButtons={PersonalOrLabelButtons}
          />
          <Box
            padding={'1rem'}
            borderTopColor={theme.colors.gray30}
            borderBottomRadius={'0.5rem'}
            width={'100%'}
            borderTop={0}
            borderLeft={'1px'}
            borderRight={'1px'}
            borderBottom={'1px'}>
            {royaltyTransferQuery.isFetched && (
              <StatementsList
                stripeAccountNotConnected={stripeAccountNotConnected}
                royaltyTransfers={royaltyTransfers}
                account={account}
                payeeType={payeeType}
                royaltyTransferQuery={royaltyTransferQuery}
              />
            )}
            {royaltyTransferQuery.isFetching && <CircularProgress />}
            {royaltyTransferQuery.hasNextPage && (
              <div style={{ marginTop: '.5rem' }}>
                <Button tertiary onClick={royaltyTransferQuery.fetchNextPage} text="Show More" />
              </div>
            )}
          </Box>
        </GridItem>
        <GridItem>
          <Box marginTop={{ md: 120, sm: 0 }}>
            <Well
              styleProps={{
                background: 'black10',
                borderRadius: '.5rem',
                color: 'black100',
                padding: '.25rem',
                ml: '.25rem',
              }}>
              <Heading size="xs" textTransform="uppercase">
                Helpful Information
              </Heading>
              <ul style={{ listStyle: 'circle' }} m="1rem .25rem">
                <li style={liSpacing}>
                  Royalties listed as <b>PAID PER MONTH</b> may be affected by recoupment. See statement details for
                  breakdown of recoupment.
                </li>
                <li style={liSpacing}>
                  Royalties collected and distributed through Venice are for the master sound recordings only.
                </li>
                <li style={liSpacing}>
                  The amount displayed as "Earnings awaiting $50 threshold" is an approximation of royalties collected
                  at a certain point in time and may periodically fluctuate.
                </li>
                <li style={liSpacing}>
                  If you are a Payee who is expecting payment from a release distributed through Venice, ensure you are
                  properly added to the release by directly contacting the Venice account holder who distributed the
                  release.
                </li>
                <li>
                  Additional questions? Check{' '}
                  <Link
                    href="https://support.venice.tech/distribution/royalties-statements"
                    target="_blank"
                    color="pink">
                    here
                  </Link>{' '}
                  for a full overview.
                </li>
              </ul>
            </Well>
          </Box>
        </GridItem>
      </Grid>
    </ViewWrapper>
  );
};

function useRoyaltyTransferSummary(account, dateRange) {
  const accountId = account?.id;
  const royaltyTransferSummaryQuery = useQuery(
    ['royaltyTransferSummaries', accountId, dateRange.startDate, dateRange.endDate],
    () =>
      Payment.getRoyaltyTransferSummariesByAccount(accountId, dateRange.startDate, dateRange.endDate).then(
        ({ transferred_amount, pending_transfer_amount }) => ({
          transferred_amount,
          pending_transfer_amount,
          accountId,
        })
      ),
    {
      enabled: !!accountId,
    }
  );

  return royaltyTransferSummaryQuery;
}

export function useRoyaltyTransfers(account, dateRange) {
  const accountIds = [account?.id];

  return useInfiniteQuery(
    ['royaltyTransfers', dateRange.startDate, dateRange.endDate, account?.id],
    ({ pageParam = 0 }) =>
      Promise.all(
        accountIds.map(accountId =>
          Payment.getRoyaltyTransfersByAccount(
            accountId,
            dateRange.startDate,
            dateRange.endDate,
            NUM_RESULTS_PAGE_SIZE,
            pageParam * NUM_RESULTS_PAGE_SIZE
          )
        )
      ).then(data => {
        return {
          data,
          prevPageParam: pageParam,
        };
      }),
    {
      enabled: account?.id !== undefined,
      getNextPageParam: ({ data: lastPage, prevPageParam }) => {
        const largestResult = Math.max(...lastPage.map(i => i?.length));
        if (largestResult >= NUM_RESULTS_PAGE_SIZE) return prevPageParam + 1;
      },
      select: data => {
        const royaltyTransfersData = data.pages.map(({ data }) => data).flat(2);

        function transform(transfers) {
          //It is possible to return dupe data across the pages of the royalty
          //transfer api, so we need to dedupe results to make sure we don't actually
          //end up displaying it.
          const royaltyIdSet = new Set();
          const dedupedTransfers = transfers.filter(r => {
            if (royaltyIdSet.has(r.id)) {
              return false;
            }
            royaltyIdSet.add(r.id);
            return true;
          });

          return dedupedTransfers
            .sort((a, b) => b.date.localeCompare(a.date))
            .map(v => {
              return { month: v.date, ...v };
            });
        }

        return transform(royaltyTransfersData);
      },
    }
  );
}

export function getDateRange(timeframe) {
  const defaultStartDate = '1900-01-01';
  const defaultEndDate = '3000-12-31';

  if (timeframe === TIMEFRAME_OPTIONS.YTD.value) {
    return {
      startDate: DateTime.fromISO(`${DateTime.local({ zone: 'UTC' }).year}-01-01`).toISO(),
      endDate: DateTime.fromISO(DateTime.local({ zone: 'UTC' }).plus({ days: 1 }).toFormat('y-MM-d')).toISO(),
    };
  }

  return {
    startDate: DateTime.fromISO(defaultStartDate, { zone: 'UTC' }).toISO(),
    endDate: DateTime.fromISO(defaultEndDate, { zone: 'UTC' }).toISO(),
  };
}

export default PayeeStatementContainer;
