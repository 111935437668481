import React from 'react';
import { Box } from '@chakra-ui/react';

const VARIANTS = {
  default: {
    background: 'brown.100',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'brown.400',
    borderRadius: 4,
    padding: 6,
  },
  large: {
    background: 'brown.400',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'brown.500',
    borderRadius: 8,
    p: { base: 4, md: 8 },
  },
  compact: {
    background: 'brown.100',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'brown.500',
    borderRadius: 4,
    paddingBottom: 4,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 4,
  },
  minimal: {
    background: 'brown.100',
    border: 'none',
    borderRadius: 0,
    padding: 5,
  },
};

export function Card({ children, variant = 'default', ...rest }) {
  const styles = VARIANTS[variant] || VARIANTS.default;

  return (
    <Box {...styles} {...rest}>
      {children}
    </Box>
  );
}
