import { Box } from '@chakra-ui/react';
import { Typography } from 'components/v2/typography/typography';
import React from 'react';

export function CardTitle({ title, description, children, ...rest }) {
  return (
    <Box mb={6} display="flex" flexDir="row" alignItems="center" justifyContent="space-between" {...rest}>
      <Box>
        <Typography variant="section-label" color="brown.700">
          {title}
        </Typography>
        <Typography variant="heading-medium">{description}</Typography>
      </Box>
      {children}
    </Box>
  );
}
