import React, { useEffect, useState } from 'react';
import * as API from '../utils/API/API';
import METRICS_PERIOD_INTERVALS from '../constants/METRICS_PERIOD_INTERVALS.gen.json';
import { SUPPORTED_DSPS } from 'analytics/supported-dsps';
import { SpotifyIcon } from 'components/icons/spotify';
import { AppleIcon } from 'components/icons/apple';
import { AmazonMusicIcon } from 'components/icons/amazon-music';
import { PandoraIcon } from 'components/icons/pandora';
import { useRoyaltyTransferHome } from './use-royalty-transfer-summary';
import useCurrentUser from 'data-client/use-current-user';
import { DateTime } from 'luxon';

const dspIcons = {
  Spotify: <SpotifyIcon boxSize={6} />,
  Apple: <AppleIcon boxSize={6} />,
  Amazon: <AmazonMusicIcon boxSize={6} />,
  Pandora: <PandoraIcon boxSize={6} />,
};

const dspColors = {
  Spotify: '#1ED760',
  Apple: '#FF335F',
  Amazon: '#24D1DA',
  Pandora: '#7190ec',
};

const Metrics = API.metrics();

const formatDSPs = (dspsList = []) => {
  const dspMap = new Map(dspsList.map(dsp => [dsp.dsp_name, dsp.streams]));

  return SUPPORTED_DSPS.map((dsp, index) => ({
    id: index,
    title: dsp,
    description: '',
    count: dspMap.get(dsp) || 0,
    icon: dspIcons[dsp],
  }));
};

const formatTopTracks = (tracks = []) => {
  return tracks?.map((track, index) => ({
    id: index + 1,
    title: track.title,
    description: track.display_artist_name,
    count: track.streams,
  }));
};

const formatTotalStreamsChartSeries = series => {
  return Object.values(
    series.reduce((acc, { date, streams }) => {
      if (!acc[date]) {
        acc[date] = { index: Object.keys(acc).length, total: 0 };
      }
      acc[date].total += streams;
      return acc;
    }, {})
  );
};

const formatTopDspsChartSeries = series => {
  return SUPPORTED_DSPS.map(dsp => ({
    dsp: dsp,
    series: series
      .filter(serie => dsp === serie.dsp_name)
      .map((serie, index) => ({
        index,
        total: serie.streams,
      })),
    color: dspColors[dsp],
  }));
};

const getDateRangeString = currentMetricsInterval => {
  const now = DateTime.now();
  const startDate = now.minus({ days: currentMetricsInterval });
  const endDate = now.minus({ days: 1 });
  const areDifferentMonths = startDate.month !== endDate.month;
  return `${startDate.toFormat('MMM d')}–${endDate.toFormat(`${areDifferentMonths ? 'MMM ' : ''}d`)}`;
};

export function useHomeNumbersWidget() {
  const [dbUser] = useCurrentUser();
  const groupId = dbUser?.currentGroup?.id;

  const [currentMetricsInterval, setCurrentMetricsInterval] = useState(METRICS_PERIOD_INTERVALS.LAST_30_DAYS);
  const [dateRangeString, setDateRangeString] = useState(null);
  const [topDsps, setTopDsps] = useState([]);
  const [topTracks, setTopTracks] = useState([]);
  const [totalStreamsChartSeries, setTotalStreamsChartSeries] = useState([]);
  const [topDspsChartSeries, setTopDspsChartSeries] = useState([]);
  const [totalStreams, setTotalStreams] = useState(0);
  const { royaltyTransferSummary, royaltyTransfersPerDay } = useRoyaltyTransferHome({ currentMetricsInterval });

  useEffect(() => {
    if (groupId) {
      setDateRangeString(getDateRangeString(currentMetricsInterval.interval));

      Metrics.getLabelMetrics(groupId, {
        metrics_interval: currentMetricsInterval,
      }).then(response => {
        const responseData = response?.data;
        setTotalStreams(responseData?.streams || 0);
        setTopDsps(formatDSPs(responseData?.rollups?.dsps || []));
        setTopTracks(formatTopTracks(responseData?.rollups?.tracks));
        setTotalStreamsChartSeries(formatTotalStreamsChartSeries(responseData.series.dsps));
        setTopDspsChartSeries(formatTopDspsChartSeries(responseData.series.dsps));
      });
    }
  }, [groupId, currentMetricsInterval]);

  const handleTimeRangeChange = event => {
    setCurrentMetricsInterval(
      Object.values(METRICS_PERIOD_INTERVALS).find(i => i.interval === parseInt(event.target.value, 10))
    );
  };

  return {
    totalStreams,
    topDspsChartSeries,
    totalStreamsChartSeries,
    topTracks,
    topDsps,
    statements: [],
    royaltyTransferSummary,
    royaltyTransfersPerDay,
    currentMetricsInterval,
    setCurrentMetricsInterval,
    handleTimeRangeChange,
    dateRangeString,
  };
}
