import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'components/card/card';
import { Box } from '@chakra-ui/react';
import { Typography } from 'components/v2/typography/typography';
import { ArrowRightIcon } from 'components/icons';
import { Button } from 'components/v2/button/button';
import { AreaSeries, Tooltip, TooltipProvider, XYChart as VisXYChart } from '@visx/xychart';
import { LinearGradient } from '@visx/gradient';
import { ParentSize } from '@visx/responsive';
import { Bar } from '@visx/shape';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import numeral from 'numeral';

export const Widget = ({ title, subtitle, children, ...rest }) => (
  <Card
    borderRadius="xl"
    display="flex"
    flexDir="column"
    gap={5}
    height="100%"
    justifyContent="space-between"
    p={8}
    variant="compact"
    {...rest}>
    {title && (
      <Typography variant="heading-small">
        {title}{' '}
        {subtitle && (
          <Box as="span" color="brown.700">
            {subtitle}
          </Box>
        )}
      </Typography>
    )}
    {children}
  </Card>
);

Widget.Chart = ({ children, ...rest }) => (
  <Box bg="brown.400" height="115px" display="flex" justifyContent="center" alignItems="center" {...rest}>
    {children}
  </Box>
);

Widget.Item = ({ index, title, description, count, icon }) => (
  <Box display="flex" flexDir="row" borderBottomWidth={1} justifyContent="space-between" borderBottomColor="brown.300">
    <Box display="flex" gap={2} alignItems="center" minH={10}>
      {icon || (
        <Typography variant="body-small" fontWeight="600" color="brown.700">
          {(index + 1).toString().padStart(2, '0')}
        </Typography>
      )}
      <Box>
        <Typography variant="body-small" color="brown.900" fontWeight="600">
          {title}
        </Typography>
        {description && (
          <Typography variant="body-small" color="brown.700">
            {description}
          </Typography>
        )}
      </Box>
    </Box>
    <Box display="flex" alignItems="center" fontWeight="600" color="brown.900">
      {count}
    </Box>
  </Box>
);

Widget.ItemList = ({ data, isNumbered }) => (
  <Box display="flex" flexDir="column" gap={2}>
    {data.map((item, index) => (
      <Widget.Item key={item.id} index={isNumbered ? index : null} {...item} />
    ))}
  </Box>
);

Widget.ViewReportButton = ({ url, title = 'See My Stats' } = {}) => (
  <Button
    alignSelf="flex-start"
    variant="link"
    linkColor="brown.900"
    endIcon={<ArrowRightIcon />}
    as={Link}
    to={url ? url : '/analytics'}>
    {title}
  </Button>
);

const accessors = {
  xAccessor: d => d.index,
  yAccessor: () => d => d.total,
};

function TotalStreamsChart({ data = {}, totalStreams = 0 }) {
  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setChartKey(prev => prev + 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ParentSize key={chartKey}>
      {({ width, height }) => (
        <Box pos="relative">
          <Box pos="absolute" top={'-18px'} left={'0'}>
            {totalStreams > 0 && (
              <Typography variant="heading-medium">{numeral(totalStreams).format('0,0')}</Typography>
            )}
          </Box>
          <VisXYChart
            width={width}
            height={height || 120}
            margin={{ top: 15, right: -35, left: -35, bottom: -30 }}
            theme={{
              colors: ['#FF335F'],
            }}
            xScale={{ type: 'band' }}
            yScale={{ type: 'linear' }}>
            <TooltipProvider>
              <LinearGradient from={'#FF335F'} fromOpacity="0.5" toOpacity="0" to={'#FFF'} id="chart-gradient" />
              <AreaSeries
                dataKey="Total Streams"
                data={data}
                xAccessor={accessors.xAccessor}
                yAccessor={accessors.yAccessor('AreaSeries')}
                fill="url('#chart-gradient')"
                lineProps={{ strokeWidth: 1 }}
              />
              <ChartTooltip />
            </TooltipProvider>
          </VisXYChart>
        </Box>
      )}
    </ParentSize>
  );
}

Widget.TotalStreamsChart = TotalStreamsChart;

function ChartTooltip() {
  return (
    <Tooltip
      snapTooltipToDatumX
      snapTooltipToDatumY
      renderTooltip={({ tooltipData }) => {
        if (!tooltipData?.nearestDatum) return null;
        const { datum, key } = tooltipData.nearestDatum;

        return (
          <Box bg="brown.100" m={'-8px'} p={2} borderRadius="4px" border="1px solid" borderColor="brown.500">
            <strong>{key}</strong>: {datum.total}
          </Box>
        );
      }}
    />
  );
}

function TopDspsChart({ data }) {
  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setChartKey(prev => prev + 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ParentSize key={chartKey}>
      {({ width, height }) => (
        <VisXYChart
          width={width}
          height={height || 120}
          margin={{ top: 5, right: -35, left: -35, bottom: 0 }}
          theme={{
            colors: data.map(d => d.color),
          }}
          xScale={{ type: 'band' }}
          yScale={{ type: 'linear' }}>
          <TooltipProvider>
            {data.map(d => (
              <AreaSeries
                key={d.dsp}
                dataKey={d.dsp}
                data={d.series}
                xAccessor={accessors.xAccessor}
                yAccessor={accessors.yAccessor(d.dsp)}
                fill="#fff"
                fillOpacity={0}
                lineProps={{
                  strokeWidth: 1,
                }}
              />
            ))}
            <ChartTooltip />
          </TooltipProvider>
        </VisXYChart>
      )}
    </ParentSize>
  );
}

Widget.TopDspsChart = TopDspsChart;

function EarningsChart({ data: dataParam = [], height = 67, margin = { top: 0, right: -5, bottom: 0, left: -5 } }) {
  let data = dataParam.map(d => ({
    category: d.day,
    value: d.transferred_amount,
  }));

  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setChartKey(prev => prev + 1);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (data.length === 0 || data.every(item => item.value === 0)) return null;

  const maxCategory = data.reduce((maxItem, item) => (item.value > maxItem.value ? item : maxItem)).category;

  return (
    <ParentSize key={chartKey}>
      {({ width }) => {
        const xScale = scaleBand({
          domain: data.map(d => d.category),
          range: [margin.left, width - margin.right],
          padding: 0.6,
        });

        const yScale = scaleLinear({
          domain: [0, Math.max(...data.map(d => d.value))],
          range: [height - margin.bottom, margin.top],
        });
        return (
          <svg width={width} height={height} style={{ borderBottom: '1px solid  t#E3D9C3' }}>
            <Group>
              {data.map(d => {
                const barWidth = xScale.bandwidth();
                const barHeight = height - margin.bottom - yScale(d.value);
                const barX = xScale(d.category);
                const barY = yScale(d.value);

                return (
                  <Bar
                    key={d.category}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    ry={1}
                    fill={maxCategory === d.category ? '#28160A' : '#E3D9C3'}
                  />
                );
              })}
            </Group>
          </svg>
        );
      }}
    </ParentSize>
  );
}

Widget.EarningsChart = EarningsChart;
